import http from "@/http";
import Token from "@/token";
import eventBus from "@/events";
import Constants from '@/constants';
import Companies from "@/companies";

const Users = {
    currentUserId: undefined,
    currentUser: undefined,
    login: async function (username, password) {
        const auth = await http.post('/users/login', {username, password});
        Token.setToken(auth.data.token);
    },
    setUserId: function (userId) {
        this.currentUserId = userId;
    },
    getCurrentUser: function () {
        return http.get('users/current-user');
    },
    logout: async function () {
        try {
            await http.post('users/logout');
            Token.removeToken();
            this.currentUser = undefined;
            this.currentUserId = undefined;
            Companies.close();
            eventBus.$emit(Constants.EVENT_WHEN_LOGOUT);
        } catch (e) {
            console.error(e);
        }
    },
    findAll: async function (ctx) {
        const page = ctx.currentPage - 1 || 0;
        const size = ctx.perPage || 20;
        const sort = ctx.sort || 'username';
        const sortDesc = ctx.sortDesc ? 'desc' : 'asc';
        const filter = ctx.filter ? ctx.filter : {};
        const pagination = {page, size, sort, sortDesc};
        const params = {...filter, ...pagination};
        const resp = await http("/users/search/all", {params});
        eventBus.$emit(Constants.EVENT_ON_USER_LIST, resp.data.page.totalElements);
        return resp.data['_embedded']['users'];
    },
    findById: async function (id) {
        return http.get('/users/' + id);
    },
    create: function (data) {
        return http.post('/users', data);
    },
    update: function (id, data) {
        return http.patch('/users/' + id, data);
    },
    usernameExists(username) {
        return http.get("/users/exists/username", {params: {username}}).then(x => {
            return !x.data;
        });
    },
    emailExists(email) {
        return http.get("/users/exists/email", {params: {email}}).then(x => {
            return !x.data;
        });
    },
    deleteById(id) {
        return http.delete("/users/" + id);
    },
    enable(id) {
        return http.patch('/users/' + id + '/enable');
    },
    disable(id) {
        return http.patch('/users/' + id + '/disable');
    },
    resetPassword(id, data) {
        return http.post('/users/' + id + '/reset-password', data);
    },
    getCurrentUserProfiles() {
        return http.get("users/current-user/profiles");
    },
    getCurrentUserPermissions() {
        return http.get("/users/current-user/permissions");
    },
    addFavoriteProduct: async function (code) {
        if (this.isFavorite(code)) {
            return;
        }
        await http.post('/users/favorites', null, {params: {code}});
        this.currentUser.favoriteProducts.push(code);
        return true;
    },
    isFavorite: function (code) {
        return this.currentUser && this.currentUser.favoriteProducts.indexOf(code) !== -1;
    },
    removeFavoriteProduct: async function (code) {
        if (this.isFavorite(code)) {
            await http.delete('/users/favorites', {params: {code}});
            const idx = this.currentUser.favoriteProducts.indexOf(code);
            this.currentUser.favoriteProducts.splice(idx, 1);
            return true;
        } else {
            return false;
        }
    },
    addToCart(product_code, product_name, quantity) {
        return http.post('/users/cart', {product_code, product_name, quantity});
    },
    getCart() {
        return http.get('/users/cart');
    },
    emptyCart() {
        return http.delete('/users/cart');
    },
    sendCart(observations) {
        return http.post('/users/cart/send', {observations});
    },
    removeFromCart(code) {
        return http.delete('/users/cart/' + code);
    },
    async findUserOrders (ctx) {
        const page = ctx.currentPage - 1 || 0;
        const size = ctx.perPage || 20;
        const sortDesc = ctx.sortDesc ? ',desc' : '';
        const sort = (ctx.sortBy || 'number') + sortDesc;
        const filter = ctx.filter ? ctx.filter : {};
        const pagination = {page, size, sort};
        const params = {...filter, ...pagination};
        params.userId = Token.accessToken.userId;
        const resp = await http("/orders/search/allOfUser", {params});
        eventBus.$emit(Constants.EVENT_ON_ORDER_LIST, resp.data.page.totalElements);
        return resp.data['_embedded']['orders'];
    },
};

export default Users;
