<template>
  <div id="app">
    <app-sidebar v-if="isAuthenticated"></app-sidebar>
    <b-alert variant="danger">{{ $t(currentError) }}</b-alert>
    <app-menu v-if="isAuthenticated"></app-menu>
    <app-messages></app-messages>
    <router-view/>
  </div>
</template>
<script>
import Token from "@/token";
import AppMenu from "@/layout/AppMenu";
import AppSidebar from "@/layout/AppSidebar";
import AppMessages from "@/layout/AppMessages";
import {Security, SendsMessages} from "@/mixins";

export default {
  name: "app",
  mixins: [SendsMessages, Security],
  components: {AppMessages, AppMenu, AppSidebar},
  watch: {
    $route(to) {
      let permission;
      switch (to.name) {
        case 'USERS_PAGE_TITLE':
          permission = 'USER_LIST_ALL_PERMISSION';
          break;
        case 'SESSIONS_PAGE_TITLE':
          permission = 'SESSION_LIST_ALL_PERMISSION';
          break;
        case 'PROFILES_PAGE_TITLE':
          permission = 'PROFILE_LIST_ALL_PERMISSION';
          break;
        case 'ORDERS_PAGE_TITLE':
          permission = 'ORDER_LIST_ALL_PERMISSION';
          break;
        default:
          permission = undefined;
          break;
      }
      if (permission && !Token.hasPermission(permission)) {
        this.sendWarning('USER_HAS_NO_PERMISSION', {page: this.$t(to.name)});
        this.$router.go(-1);
      }
    }
  },
  mounted() {
    if (Token.isNotAuthenticated()) {
      if (this.$route.path.indexOf('login') === -1) {
        this.$router.push({path: 'login'})
      }
    }
  },
  data() {
    return {
      currentError: undefined,
    }
  },
}
</script>
<style>
</style>
