<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <h1>{{ $t(title) }}</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <router-view></router-view>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: "UsersPage",
  computed: {
    title() {
      return this.$route.name;
    }
  }
}
</script>

<style scoped>

</style>
