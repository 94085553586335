import VueI18n from 'vue-i18n';
import Vue from "vue";

const messages = {
    es: {
        ORDER_LIST_OBSERVATIONS_LABEL: 'Observaciones',
        ORDER_OBSERVATIONS_LABEL: 'Agregar alguna nota al pedido? (deje en blanco si no desea agregar nada)',
        ORDERS_PRODUCT_LABEL: 'Producto',
        ORDERS_QUANTITY_LABEL: 'Cantidad',
        ORDERS_MEASURE_UNIT_LABEL: 'Unidad de medida',
        ORDERS_UNITARY_PRICE_LABEL: 'Precio unitario',
        ORDERS_SUBTOTAL_LABEL: 'Total',
        // Login
        ORDER_REQUEST_DATE_LABEL: 'Fecha de pedido',
        ORDER_IMPORT_DATE_LABEL: 'Fecha de remisi\u00F3n',
        ORDER_REMISION_NUMBER_LABEL: 'Numero de remisi\u00F3n',
        ORDER_SERIES_LABEL: 'Serie',
        CONFIRM_EMPTYING_CART: 'Esta seguro que desea eliminar todos los articulos en su pedido?',
        PRICE_WENT_UP: '',
        PRICE_WENT_DOWN: '',
        SEND_ORDER_LABEL: 'Realizar pedido',
        PRODUCT_LABEL: 'Producto',
        UNIT_PRICE_LABEL: 'Precio unitario',
        SUBTOTAL_LABEL: 'Total',
        QUANTITY_LABEL: 'Cantidad',
        LOGIN_PAGE_TITLE: 'Iniciar sesi\u00F3n',
        FULL_NAME_LABEL: 'Nombre completo',
        ENABLED_LABEL: 'Habilitado',
        LOGIN_BUTTON_LABEL: 'Iniciar sesi\u00F3n',
        SELECT_COMPANY_TITLE: 'Seleccione la empresa de trabajo',
        COMPANY_LABEL: 'Empresa',
        NO_COMPANY_LABEL: 'No hay empresa abierta',
        CHANGE_COMPANY_LABEL: 'Cambiar de empresa',
        LOGOUT_LABEL: 'Cerrar sesi\u00F3n',
        NO_USER_LABEL: 'No hay usuario',
        USER_DETAILS_LABEL: 'Ver detalles',
        USER_EDIT_LABEL: 'Editar usuario',
        USER_DELETE_LABEL: 'Eliminar usuario',
        USERS_PAGE_TITLE: 'Usuarios',
        PROFILES_PAGE_TITLE: 'Perfiles',
        SESSIONS_PAGE_TITLE: 'Sesiones',
        MENU_LABEL: 'Menu',
        USERS_ADD_LABEL: 'Agregar nuevo usuario',
        USER_PASSWORD_CHANGE_LABEL: 'Cambiar contrase\u00F1a',
        USER_ENABLE_LABEL: 'Habilitar usuario',
        USER_DISABLE_LABEL: 'Deshabilitar usuario',
        USER_LOADING_LABEL: 'Cargando usuarios...',
        COMPANY_SELECT_BUTTON_LABEL: 'Seleccionar empresa',
        QUOTE_PAGE_TITLE: 'Requisiciones',
        STATUS_SENT_LABEL: 'Enviada',
        STATUS_QUOTED_LABEL: 'Cotizada',
        STATUS_ACCEPTED_LABEL: 'En autorizacion',
        STATUS_PROCESSED_LABEL: 'Procesada',
        STATUS_REJECTED_LABEL: 'Rechazada',
        STATUS_UNKNOWN_LABEL: 'Desconocido',
        OPTIONS_LABEL: 'Opciones',
        GENERAL_DATA_TITLE: 'Datos generales',
        PROFILES_TITLE: 'Perfiles',
        PERMISSIONS_TITLE: 'Permisos',

        PERMISSION_PERMISSION_CATEGORY: 'Permisos',
        PROFILE_PERMISSION_CATEGORY: 'Perfiles',
        USER_PERMISSION_CATEGORY: 'Usuarios',
        SESSION_PERMISSION_CATEGORY: 'Sesiones',

        EVENT_WHEN_USER_COMES_BACK: 'EVENT_WHEN_USER_COMES_BACK',
        EVENT_WHEN_AUTHENTICATION_EXPIRES: 'EVENT_WHEN_AUTHENTICATION_EXPIRES',
        EVENT_INVALID_CREDENTIALS: 'EVENT_INVALID_CREDENTIALS',
        EVENT_COULD_NOT_GET_CURRENT_USER: 'EVENT_COULD_NOT_GET_CURRENT_USER',
        EVENT_COULD_NOT_GET_QUOTES: 'EVENT_COULD_NOT_GET_QUOTES',
        EVENT_COULD_NOT_GET_COMPANIES: 'EVENT_COULD_NOT_GET_COMPANIES',
        EVENT_WHEN_NO_COMPANIES: 'EVENT_WHEN_NO_COMPANIES',
        EVENT_WHEN_DATA_SAVED: 'EVENT_WHEN_DATA_SAVED',

        USERNAME_LABEL: 'Usuario',
        USERNAME_INPUT_DESCRIPTION: 'Capture el usuario que se utilizara para iniciar sesi\u00F3n',
        ERROR_USERNAME_REQUIRED: 'El campo usuario no debe estar vacio',
        ERROR_USERNAME_MINLENGTH: 'El campo usuario debe contener al menos {min} caracteres',
        ERROR_USERNAME_MAXLENGTH: 'El campo usuario debe contener un m\u00E1ximo de {max} caracteres',
        ERROR_USERNAME_ISUNIQUE: 'El usuario capturado ya existe, por favor especifique otro.',

        SAVE_LABEL: 'Guardar',
        SAVING_LABEL: 'Guardando...',

        NAME_LABEL: 'Nombre',
        NAME_INPUT_DESCRIPTION: 'Capture el nombre de la persona responsable de la cuenta.',
        ERROR_NAME_REQUIRED: 'El campo nombre no debe estar vacio',
        ERROR_NAME_MINLENGTH: 'El campo nombre debe contener al menos {min} caracteres',
        ERROR_NAME_MAXLENGTH: 'El campo nombre debe contener un m\u00E1ximo de {max} caracteres',

        LAST_NAME_LABEL: 'Apellidos',
        LAST_NAME_INPUT_DESCRIPTION: 'Especifique el  que utilizara para iniciar sesi\u00F3n',
        ERROR_LAST_NAME_REQUIRED: 'El campo  no debe estar vacio',
        ERROR_LAST_NAME_MINLENGTH: 'El campo  debe contener al menos {min} caracteres',
        ERROR_LAST_NAME_MAXLENGTH: 'El campo  debe contener un m\u00E1ximo de {max} caracteres',

        EMAIL_LABEL: 'Correo electr\u00F3nico',
        EMAIL_INPUT_DESCRIPTION: 'Especifique el correo electr\u00F3nico de contacto para este usuario.',
        ERROR_EMAIL_REQUIRED: 'El campo correo electr\u00F3nico no debe estar vacio',
        ERROR_EMAIL_MINLENGTH: 'El campo correo electr\u00F3nico debe contener al menos {min} caracteres',
        ERROR_EMAIL_MAXLENGTH: 'El campo correo electr\u00F3nico debe contener un m\u00E1ximo de {max} caracteres',
        ERROR_EMAIL_EMAIL: 'El campo correo electr\u00F3nico debe tener un formato valido',
        ERROR_EMAIL_ISUNIQUE: 'El correo electr\u00F3nico capturado ya existe, por favor especifique otro.',
        ERROR_INVALID_CREDENTIALS: 'Error al iniciar sesi\u00F3n verifique su usuario y contrase\u00F1a',

        PASSWORD_LABEL: 'Contrase\u00F1a',
        PASSWORD_INPUT_DESCRIPTION: 'Especifique la contrase\u00F1a que utilizara para iniciar sesi\u00F3n',
        ERROR_PASSWORD_REQUIRED: 'El campo contrase\u00F1a no debe estar vacio',
        ERROR_PASSWORD_MINLENGTH: 'El campo contrase\u00F1a debe contener al menos {min} caracteres',
        ERROR_PASSWORD_MAXLENGTH: 'El campo contrase\u00F1a debe contener un m\u00E1ximo de {max} caracteres',

        PASSWORD_VERIFICATION_LABEL: 'Verificar la contrase\u00F1a',
        PASSWORD_VERIFICATION_INPUT_DESCRIPTION: 'Verifique la contrase\u00F1a',
        ERROR_PASSWORD_VERIFICATION_REQUIRED: 'El campo verificar la contrase\u00F1a no debe estar vacio',
        ERROR_PASSWORD_VERIFICATION_MINLENGTH: 'El campo verificar la contrase\u00F1a debe contener al menos {min} caracteres',
        ERROR_PASSWORD_VERIFICATION_MAXLENGTH: 'El campo verificar la contrase\u00F1a debe contener un m\u00E1ximo de {max} caracteres',
        ERROR_PASSWORD_VERIFICATION_SAMEASPASSWORD: 'El campo verificar y contrase\u00F1a no coinciden',

        PERMISSION_LIST_ALL_PERMISSION: 'Permiso para consultar todos los permisos disponibles.',
        PROFILE_CREATE_PERMISSION: 'Permiso para crear nuevos perfiles',
        PROFILE_DELETE_PERMISSION: 'Permiso para borrar perfiles permanentemente',
        PROFILE_DISABLE_PERMISSION: 'Permiso para deshabilitar perfiles',
        PROFILE_ENABLE_PERMISSION: 'Permiso para habilitar perfiles',
        PROFILE_LIST_ALL_PERMISSION: 'Permiso acceder a la pagina de permisos',
        PROFILE_UPDATE_PERMISSION: 'Permiso para actualizar perfiles',
        SESSION_DELETE_PERMISSION: 'Permiso para cerrar sesiones de cualquier usuario.',
        SESSION_LIST_ALL_PERMISSION: 'Permiso para acceder a la pagina de sesiones',
        USER_CREATE_PERMISSION: 'Permiso para crear nuevos usuarios',
        USER_DELETE_PERMISSION: 'Permiso para eliminar permanentemente usuarios',
        USER_DETAIL_PERMISSION: 'Permiso para ver los detalles de los usuarios',
        USER_DISABLE_PERMISSION: 'Permiso para deshabilitar usuarios',
        USER_ENABLE_PERMISSION: 'Permiso para habilitar usuarios',
        USER_LIST_ALL_PERMISSION: 'Permiso para acceder a la pagina de usuarios',
        USERS_REGISTER_TITLE: 'Registrar nuevo usuario',
        USER_PASSWORD_CHANGE_PERMISSION: 'Permiso para cambiar la contrase\u00F1a de cualquier usuario',
        USER_PASSWORD_RECOVERY_PERMISSION: 'Permiso para enviar el correo de recuperaci\u00F3n de la contrase\u00F1a de cualquier usuario',
        USER_UPDATE_PERMISSION: 'Permiso para editar usuarios',

        PROFILE_NAME_LABEL: 'Perfil',
        PROFILE_LOADING_LABEL: 'Cargando perfiles',
        PROFILES_ADD_LABEL: 'Agregar nuevo perfil',
        SEARCH_PERMISSION_PLACEHOLDER: 'Buscar en permisos',
        PERMISSION_LOADING_LABEL: 'Cargando permisos',

        EVENT_USER_UPDATED: 'El usuario "{username}" ha sido actualizado exitosamente',
        EVENT_USER_CREATED: 'El usuario "{username}" ha sido creado exitosamente',
        ERROR_USER_CREATED: 'Error al registrar el usuario, detalles',
        ERROR_USER_UPDATED: 'Error al actualizar el usuario, detalles',
        USERS_EDIT_TITLE: 'Editar usuario',

        CONFIRM_USER_DELETE: 'Desea eliminar el usuario "{username}"?',
        ERROR_DELETING_USER: 'Ocurrio un error al eliminar el usuario "{username}", verifique que el usuario no tenga movimientos asociados.',
        USER_DELETED_SUCCESSFULLY: 'Usuario "{username}" eliminado exitosamente',
        USERS_REFRESH_LABEL: 'Actualizar',
        USER_ENABLED_SUCCESSFULLY: 'El usuario "{username}" ha sido habilitado.',
        USER_DISABLED_SUCCESSFULLY: 'El usuario "{username}" ha sido deshabilitado.',
        USER_DATA_LOADING_LABEL: 'Cargando datos del usuario',
        USER_CHANGE_PASSWORD: 'Cambiar la contrase\u00F1a del usuario "{username}"',
        USER_PASSWORD_CHANGED: 'La contrase\u00F1a del usuario "{username}" ha sido actualizada',
        PROFILE_DATA_LOADING_LABEL: 'Cargando datos del perfil',
        PROFILES_REGISTER_TITLE: 'Registrar nuevo perfil',
        PROFILE_NAME_INPUT_DESCRIPTION: 'Capture el nombre del perfil',

        ERROR_PROFILE_NAME_REQUIRED: 'Especifique el nombre de este perfil.',
        ERROR_PROFILE_NAME_MINLENGTH: 'El campo perfil debe contener al menos {min} caracteres',
        ERROR_PROFILE_NAME_MAXLENGTH: 'El campo perfil debe contener un m\u00E1ximo de {max} caracteres',
        ERROR_PROFILE_NAME_ISUNIQUE: 'El nombre del perfil ya esta en uso, especifique otro',
        EVENT_PROFILE_CREATED: 'Perfil "{name}" creado exitosamente',
        PROFILES_EDIT_TITLE: 'Editar perfil',
        EVENT_PROFILE_UPDATED: 'Perfil "{name}" actualizado exitosamente',
        PROFILE_EDIT_LABEL: 'Editar perfil',
        PROFILE_DELETE_LABEL: 'Eliminar perfil',
        PROFILE_DISABLE_LABEL: 'Deshabilitar perfil',
        PROFILE_ENABLE_LABEL: 'Habilitar perfil',

        PROFILE_ENABLED_SUCCESSFULLY: 'El perfil "{name}" ha sido habilitado.',
        ERROR_ENABLING_PROFILE: 'Ocurrio un error al habilitar el perfil "{name}".',
        PROFILE_DISABLED_SUCCESSFULLY: 'El perfil "{name}" ha sido deshabilitado.',
        CONFIRM_PROFILE_DELETE: 'Desea eliminar el perfil "{name}"?',
        USERS_UPLOAD_EXCEL_LABEL: 'Cargar usuarios desde excel',
        USERS_DOWNLOAD_EXCEL_LABEL: 'Bajar usuarios a excel',
        ACCESS_TOKEN_LABEL: 'Sesi\u00F3n normal',
        SESSION_IP_LABEL: 'IP',
        SESSION_TYPE_LABEL: 'Tipo de sesi\u00F3n',
        SESSION_USERNAME_LABEL: 'Usuario',
        SESSIONS_LOADING_LABEL: 'Cargando sesiones',
        USER_AGENT_LABEL: 'Navegador',
        SESSION_DISABLE_LABEL: 'Cerrar sesi\u00F3n',
        CONFIRM_SESSION_DISABLE: 'Esta seguro que desea cerrar esta sesi\u00F3n',
        SESSION_DISABLED_SUCCESSFULLY: 'Sesi\u00F3n cerrada correctamente',
        ERROR_DISABLING_SESSION: 'Ocurrio un error al cerrar la sesi\u00F3n',
        SESSION_EXPIRATION_LABEL: 'Fecha expiraci\u00F3n',
        USER_HAS_NO_PERMISSION: 'Usted no tiene permiso para ver la pagina "{page}"',
        PROFILE_LABEL: 'Ver perfil',
        PROFILE_PAGE_TITLE: 'Perfil',
        USER_PROFILE_LABEL: 'Perfil del usuario "{username}"',
        USER_PROFILE_LOADING_LABEL: 'Cargando perfil de usuario',
        USER_PERMISSIONS_LABEL: 'Permisos asignados al usuario "{username}"',
        USER_PROFILES_LABEL: 'Perfiles asignados al usuario "{username}"',
        NO_PERMISSIONS_LABEL: 'El usuario {username} no tiene permisos asignados',
        NO_PROFILES_LABEL: 'El usuario {username} no tiene perfiles asignados',
        ROOT_PERMISSIONS_LABEL: 'El usuario administrador tiene todos los permisos, no es necesario asignar permisos adicionales',
        ROOT_PROFILES_LABEL: 'EL usuario administrador tiene el perfil mas alto del sistema, otros perfiles son innecesarios',
        USER_SEARCH_LABEL: 'Buscar usuario',
        USER_SEARCH_INPUT_DESCRIPTION: 'Los usuarios se filtraran por "usuario", "nombre completo" o "correo electr\u00F3nico"',
        FILTER_USERS_LABEL: 'Estado del usuario',
        ALL_USERS_LABEL: 'Todos los usuarios',
        ACTIVE_USERS_LABEL: 'Usuarios activos',
        INACTIVE_USERS_LABEL: 'Usuarios inactivos',
        PROFILE_SEARCH_INPUT_DESCRIPTION: 'Los perfiles se filtraran por "perfil"',
        PROFILE_SEARCH_LABEL: 'Buscar perfil',
        FILTER_PROFILES_LABEL: 'Estado del perfil',
        ALL_PROFILES_LABEL: 'Todos los perfiles',
        ACTIVE_PROFILES_LABEL: 'Perfiles activos',
        INACTIVE_PROFILES_LABEL: 'Perfiles inactivos',
        SESSION_SEARCH_LABEL: 'Buscar sesi\u00F3n',
        SESSION_FROM_LABEL: 'Desde',
        SESSION_TO_LABEL: 'Hasta',
        FILTER_SESSIONS_LABEL: 'Estado de la sesi\u00F3n',
        SESSION_SEARCH_INPUT_DESCRIPTION: 'Las sesiones se filtraran por "usuario", "ip" y "navegador"',
        ALL_SESSIONS_LABEL: 'Todas las sesiones',
        ACTIVE_SESSIONS_LABEL: 'Sesiones activas',
        INACTIVE_SESSIONS_LABEL: 'Sesiones inactivas',
        COMPANY_SELECT_LABEL: 'Seleccione la empresa de trabajo',
        COMPANY_LOADING_LABEL: 'Cargando empresas',
        NO_COMPANY_AVAILABLE_MESSAGE: 'El usuario {username} no tiene asociada una empresa o no cuenta con el permiso de cambio de empresa, el usuario no podra trabajar.',
        COMPANY_OPEN_LABEL: 'Seleccionar',
        COMPANY_PERMISSION_CATEGORY: 'Empresas',
        ORDER_PERMISSION_CATEGORY: 'Pedidos',
        PRODUCT_PERMISSION_CATEGORY: 'Productos',
        COMPANY_SELECT_PERMISSION: 'Permiso para seleccionar/cambiar empresa de trabajo',
        PRODUCT_LIST_ALL_PERMISSION: 'Acceso a la pagina de productos',
        ORDER_DELETE_PERMISSION: 'Permiso para borrar un pedido',
        ORDER_CHANGE_STATUS_PERMISSION: 'Permiso para cambiar el estado de una orden',
        ORDER_LIST_ALL_PERMISSION: 'Acceso a la pagina de todos los pedidos',
        PRODUCTS_PAGE_TITLE: 'Productos',
        FAVORITES_PAGE_TITLE: 'Mis favoritos',
        MY_ORDERS_PAGE_TITLE: 'Mis pedidos',
        ORDERS_PAGE_TITLE: 'Todos los pedidos',
        ERROR_LOADING_CATEGORIES: 'Ocurrio un error al cargar las categorias de productos',
        ERROR_WHILE_SETTING_CURRENT_COMPANY: 'Ocurrio un error al definir la empresa actual.',
        PRODUCT_LOADING_LABEL: 'Cargando productos',
        PRICE_LABEL: 'Precio',
        EXISTENCE_LABEL: 'Existencia',
        ADD_PRODUCT_MODAL_TITLE: 'Agregar {product_name} al pedido',
        STOCK_AVAILABLE_LABEL: 'Especifique la cantidad a ordenar, (disponible: {existence})',
        ADD_TO_CART_LABEL: 'Agregar al pedido',
        QUANTITY_ERROR_NUMERIC: 'Debe especificar un valor numerico',
        QUANTITY_ERROR_REQUIRED: 'Debe especificar la cantidad',
        QUANTITY_ERROR_MIN: 'Debe especificar un valor mayor a 0',
        QUANTITY_ERROR_MAX: 'Debe especificar un valor menor o igual a la existencia disponible',
        PRODUCT_SEARCH_LABEL: 'Buscar producto',
        PRODUCT_SEARCH_INPUT_DESCRIPTION: 'Se buscara por "nombre del producto", "descripcion" y "etiquetas"',
        NUMBER_LABEL: 'Folio',
        ORDER_DATE_LABEL: 'Fecha de pedido',
        ORDER_STATUS_LABEL: 'Estado del pedido',
        ORDER_TOTAL_LABEL: 'Total del pedido',
        ORDER_CUSTOMER_LABEL: 'Cliente',
        ORDER_SEARCH_LABEL: 'Buscar pedido por folio',
        ORDER_SEARCH_INPUT_DESCRIPTION: 'Se buscara el pedido unicamente por "folio"',
        FILTER_ORDER_LABEL: 'Estado de la orden',
        FILTER_COMPANY_LABEL: 'Filtrar por empresa',
        ALL_ORDERS: 'Todas las ordenes',
        ALL_COMPANIES_LABEL: 'Todas las empresas',
        ALL_CUSTOMERS_LABEL: 'Todos los clientes',
        FILTER_CUSTOMER_LABEL: 'Filtrar por cliente',
        ORDER_LOADING_LABEL: 'Cargando ordenes',
        ERROR_WHEN_ADDED_TO_CART: 'Error al agregar articulo al carrito',
        ORDER_DELETE_LABEL: 'Eliminar pedido',
        ORDER_CHANGE_STATUS_LABEL: 'Cambiar estado del pedido',
        CURRENT_ORDER_LABEL: 'Articulos en el pedido',
        EMPTY_CART_LABEL: 'Eliminar todos los articulos',
        REMOVE_LABEL: 'Remover',
        SUCCESSFULLY_EMPTY_CART: 'Su carrito de compras esta vacio',
        SUCCESSFULLY_SENT_ORDER: 'Orden {number} generada correctamente.',
        SUCCESSFULLY_REMOVED_PRODUCT: 'Producto {product} eliminado.',
        CART_LOADING_LABEL: 'Realizando cambios',
        ORDER_STATUS_DRAFT: 'Borrador',
        ORDER_STATUS_IN_PROCESS: 'En proceso',
        ORDER_STATUS_CANCELLED_BY_USER: 'Cancelado por el usuario',
        ORDER_STATUS_PROCESSED: 'Procesada',
        ORDER_STATUS_CANCELLED_BY_CUSTOMER: 'Cancelado por el cliente',
        EMPTY_CART_MESSAGE: 'Su pedido esta vacio',
        CHANGE_STATUS_LABEL: 'Cambiar el estado',
        ORDER_STATUS: 'Estado de la orden',
        ORDER_CHANGE_STATUS: 'Cambiar el estado de la orden {number}',
        STATUS_CHANGED_SUCCESSFULLY: 'El estado de la orden {number} fue cambiado.',
        ERROR_CHANGING_STATUS: 'Ocurrio un error al cambiar el estado de la orden {number}',
        ORDER_CANCEL_LABEL: 'Cancelar orden',
        ORDER_CANCELLED_SUCCESSFULLY: 'La orden {number} ha sido cancelada.',
        ERROR_CANCELLING_ORDER: 'Ocurrio un error al cancelar la orden {number}',
        CONFIRM_CANCEL_ORDER: 'Esta seguro que desea cancelar el pedido {number}?',
        CUSTOMER_SELECT_LABEL: 'Seleccione el cliente asignado a este usuario',
        CUSTOMER_LOADING_LABEL: 'Cargando clientes',
        CONFIRM_ORDER_DELETE: 'Confirma que desea eliminar la orden {number}?',
        ORDER_DELETED_SUCCESSFULLY: 'Orden {number} eliminada.',
        ERROR_DELETING_ORDER: 'Ocurrio un error al eliminar la orden {number}, por favor intente mas tarde.',
    }
};

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: navigator.language.split('-')[0],
    fallbackLocale: 'es',
    messages,
});

export default i18n;
