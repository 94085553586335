<template>
  <div>
    <b-alert :variant="message.type" v-for="message in messages" :key="message.id" @dismissed="remove(message.id)" show
             dismissible>
      {{ $t(message.message, message.params) }}
      <pre v-if="message.error">
        {{ message.error }}
      </pre>
    </b-alert>
  </div>
</template>

<script>
import eventBus from "@/events";
import Constants from "@/constants";

export default {
  name: "AppMessages",
  data() {
    return {
      messages: [],
      messageCount: 0
    }
  },
  mounted() {
    this.messages = [];
    this.messageCount = 0;
    eventBus.$on(Constants.EVENT_WHEN_MESSAGE_DISPLAY, (message, params, type, error) => {
      this.messages.push({id: this.messageCount, message, params, type, error});
      this.messageCount++;
    });
  },
  methods: {
    remove(message) {
      const idx = this.messages.map(m => m.id).indexOf(message);
      if (idx !== -1) {
        this.messages.splice(idx, 1);
      }
    }
  }
}
</script>

<style scoped>

</style>
