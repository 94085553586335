<template>
  <div>
    <div class="pb-3">
      <b-button v-has-permission="'ORDER_CREATE_PERMISSION'" @click="add()" variant="info">
        <b-icon-plus-circle></b-icon-plus-circle>
        <span class="ml-1">{{ $t('USERS_ADD_LABEL') }}</span>
      </b-button>

      <b-button class="ml-2" @click="refresh" variant="info">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('USERS_REFRESH_LABEL') }}</span>
      </b-button>
    </div>

    <b-form>
      <b-container fluid>
        <b-row>
          <b-col>
            <b-form-group
                id="search-form-group"
                :label="$t('SESSION_FROM_LABEL')"
                label-for="search-from">
              <b-input-group>
                <b-form-input
                    id="example-input"
                    v-model="filter.from"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                      v-model="filter.from"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="example-input"
                      @context="onContext"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

          </b-col>
          <b-col>
            <b-form-group
                id="search-form-group"
                :label="$t('SESSION_TO_LABEL')"
                label-for="search-to">
              <b-input-group>
                <b-form-input
                    id="example-input"
                    v-model="filter.to"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker right button-only id="search-to" v-model="filter.to"></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>

            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
                id="search-form-group"
                :description="$t('ORDER_SEARCH_INPUT_DESCRIPTION')"
                :label="$t('ORDER_SEARCH_LABEL')"
                label-for="search-input">
              <b-form-input id="search-input" lazy v-model="filter.number"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
                id="status-form-group"
                :label="$t('FILTER_ORDER_LABEL')"
                label-for="status-select"
            >
              <b-form-select v-model="filter.status" id="status-select">
                <b-form-select-option :value="undefined">{{ $t('ALL_ORDERS') }}</b-form-select-option>
                <b-form-select-option v-for="status in orderStatus" :key="status.id" :value="status.id">{{
                    status.label
                  }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col v-if="!ownOrders">
            <b-form-group
                id="company-form-group"
                :label="$t('FILTER_COMPANY_LABEL')"
                label-for="company-select"
            >
              <single-company-selector :show-undefined="true" v-model="filter.company"></single-company-selector>
            </b-form-group>
          </b-col>

          <b-col v-if="!ownOrders">
            <b-form-group

                id="customer-form-group"
                :label="$t('FILTER_CUSTOMER_LABEL')"
                label-for="customer-select"
            >
              <single-customer-selector v-if="filter.company" :show-undefined="true" :company="filter.company"
                                        v-model="filter.customer"></single-customer-selector>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
    <b-table
        @row-clicked="showDetails"
        id="users-table"
        ref="usersTable"
        striped
        hover
        bordered
        small
        v-model="items"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :filter="filter"
        :items="getOrders">

      <template #row-details="row">
        <div v-if="loadingDetails[row.item.id]">
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('ORDERS')}) }}</strong>
          </div>
        </div>

        <div v-else>
          <b-table-simple table-variant="dark" small responsive>
            <b-thead>
              <b-tr>
                <b-th>{{ $t('ORDERS_PRODUCT_LABEL')  }}</b-th>
                <b-th>{{ $t('ORDERS_QUANTITY_LABEL')  }}</b-th>
                <b-th>{{ $t('ORDERS_MEASURE_UNIT_LABEL')  }}</b-th>
                <b-th>{{ $t('ORDERS_UNITARY_PRICE_LABEL')  }}</b-th>
                <b-th>{{ $t('ORDERS_SUBTOTAL_LABEL')  }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="detail in details$[row.item.id].details" :key="detail.id">
                <b-td>{{ detail.productName }}</b-td>
                <b-td>{{ detail.quantity }}</b-td>
                <b-td>{{ detail.measureUnit }}</b-td>
                <b-td>{{detail.price}}</b-td>
                <b-td>{{ detail.total }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </template>

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('ORDER_LOADING_LABEL') }}</strong>
        </div>
      </template>

      <template #head(number)="">
        <span>{{ $t('NUMBER_LABEL') }}</span>
      </template>

      <template #cell(number)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.number }}</span>
        </div>
      </template>

      <template #head(date)="">
        <span>{{ $t('ORDER_REQUEST_DATE_LABEL') }}</span>
      </template>

      <template #head(observations)="">
        <span>{{ $t('ORDER_LIST_OBSERVATIONS_LABEL') }}</span>
      </template>

      <template #cell(date)="data">
        <span>{{ data.item.date | date('DD/MM/YYYY HH:mm:ss') }}</span>
      </template>

      <template #head(importDate)="">
        <span>{{ $t('ORDER_IMPORT_DATE_LABEL') }}</span>
      </template>

      <template #cell(importDate)="data">
        <span>{{ data.item.importDate | date('DD/MM/YYYY HH:mm:ss') }}</span>
      </template>

      <template #head(series)="">
        <span>{{ $t('ORDER_SERIES_LABEL') }}</span>
      </template>

      <template #cell(series)="data">
        <span>{{ data.item.series }}</span>
      </template>

      <template #head(importNumber)="">
        <span>{{ $t('ORDER_REMISION_NUMBER_LABEL') }}</span>
      </template>

      <template #cell(importNumber)="data">
        <span>{{ data.item.importNumber }}</span>
      </template>

      <template #head(status)="">
        <span>{{ $t('ORDER_STATUS_LABEL') }}</span>
      </template>

      <template #cell(status)="data">
        <span>{{ $t('ORDER_STATUS_' + data.item.status) }}</span>
      </template>

      <template #head(total)="">
        <span>{{ $t('ORDER_TOTAL_LABEL') }}</span>
      </template>

      <template #cell(total)="data">
        <span>{{ data.item.total | currency }}</span>
      </template>

      <template #head(customer)="">
        <span>{{ $t('ORDER_CUSTOMER_LABEL') }}</span>
      </template>

      <template #cell(customer)="data">
        <span>{{ data.item.customer }}</span>
      </template>

      <template #head(enabled)="">
        <span>{{ $t('ENABLED_LABEL') }}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(options)="">
        <span>{{ $t('OPTIONS_LABEL') }}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown size="sm" id="dropdown-1" right class="m-md-2">
            <b-dropdown-item v-if="!ownOrders" v-has-permission="'ORDER_DELETE_PERMISSION'"
                             @click="remove(data.item.id, data.item.number)">{{ $t('ORDER_DELETE_LABEL') }}
            </b-dropdown-item>
            <b-dropdown-item v-if="!ownOrders" v-has-permission="'ORDER_CHANGE_STATUS_PERMISSION'"
                             @click="openChangeStatus(data.item)">
              {{ $t('ORDER_CHANGE_STATUS_LABEL') }}
            </b-dropdown-item>
            <b-dropdown-item v-if="ownOrders" :disabled="data.item.status !== 'IN_PROCESS'"
                             @click="cancelOrder(data.item)">
              {{ $t('ORDER_CANCEL_LABEL') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>

    <b-pagination
        v-model="page"
        :total-rows="totalRows"
        :per-page="size"
        aria-controls="users-table"
    ></b-pagination>

    <b-modal id="order-change-status-modal" :title="$t('ORDER_CHANGE_STATUS', {number: currentOrder.number})"
             hide-footer>
      <b-card>
        <b-card-text>
          <b-form @submit.prevent="changeStatus(currentOrder.id, currentOrder.number)">
            <b-form-group
                :label="$t('ORDER_STATUS')">
              <b-form-select class="mb-2" v-model="currentOrder.status">
                <b-select-option v-for="status in orderStatus" :key="status.id" :value="status.id">{{ status.label }}
                </b-select-option>
              </b-form-select>
              <b-button :disabled="changingStatus" variant="primary" type="submit">
                <b-icon-circle-fill v-if="changingStatus" animation="throb"></b-icon-circle-fill>
                {{ $t('CHANGE_STATUS_LABEL') }}
              </b-button>
            </b-form-group>
          </b-form>
        </b-card-text>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {DateFilter, Form, NumberFilter, Table} from "@/mixins";
import eventBus from "@/events";
import Constants from "@/constants";
import Orders from "@/orders/index";
import SingleCustomerSelector from "@/customers/SingleCustomerSelector";
import SingleCompanySelector from "@/companies/SingleCompanySelector";
import Users from "@/users";

export default {
  name: "OrderList",
  components: {SingleCompanySelector, SingleCustomerSelector},
  mixins: [Table, Form, DateFilter, NumberFilter],
  mounted() {
    eventBus.$on(Constants.EVENT_ON_ORDER_LIST, (totalElements) => {
      this.totalRows = totalElements;
    });
  },
  methods: {
    async showDetails(row) {
      try {
        row._showDetails = !row._showDetails;
        if (row._showDetails) {
          this.loadingDetails$[row.id] = true;
          const resp = await Orders.findById(row.id);
          this.details$[row.id] = resp.data;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingDetails[row.id] = false;
        this.$forceUpdate();
        console.log(this.loadingDetails$[row.id])
      }
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
    getOrders: function (ctx) {
      if(ctx.filter.from) {
        ctx.filter.from = ctx.filter.from + ' 00:00:00';
      }
      if(ctx.filter.to) {
        ctx.filter.to = ctx.filter.to + ' 23:59:59';
      }
      return this.ownOrders ? Users.findUserOrders(ctx) : Orders.findAll(ctx);
    },
    refresh: function () {
      if(this.filter.from) {
        this.filter.from = this.filter.from + ' 00:00:00';
      }
      if(this.filter.to) {
        this.filter.to = this.filter.to + ' 23:59:59';
      }
      this.$refs.usersTable.refresh()
    },
    remove: async function (id, number) {
      if (confirm(this.$t('CONFIRM_ORDER_DELETE', {number}))) {
        try {
          this.isBusy = true;
          await Orders.deleteById(id);
          this.sendMessage('ORDER_DELETED_SUCCESSFULLY', {number});
          this.removeRow(id);
        } catch (e) {
          console.error(e);
          this.sendError('ERROR_DELETING_ORDER', {number})
        } finally {
          this.isBusy = false;
        }
      }
    },
    openChangeStatus: async function (order) {
      this.currentOrder = order;
      this.$bvModal.show('order-change-status-modal');
    },
    async changeStatus() {
      try {
        this.changingStatus = true;
        await Orders.changeStatus(this.currentOrder.id, this.currentOrder.status);
        this.closeChangeStatus();
        this.sendMessage('STATUS_CHANGED_SUCCESSFULLY', this.currentOrder);
      } catch (e) {
        this.sendError('ERROR_CHANGING_STATUS', this.currentOrder, e);
      } finally {
        this.changingStatus = false;
      }
    },
    closeChangeStatus: function () {
      this.$bvModal.hide('order-change-status-modal');
    },
    async cancelOrder(order) {
      try {
        this.isBusy = true;
        if (confirm(this.$t('CONFIRM_CANCEL_ORDER', order))) {
          await Orders.cancelOrder(order.id);
          order.status = 'CANCELLED_BY_CUSTOMER';
          this.sendMessage('ORDER_CANCELLED_SUCCESSFULLY', order);
        }
      } catch (e) {
        this.sendError('ERROR_CANCELLING_ORDER', order, e);
      } finally {
        this.isBusy = false;
      }
    }
  },
  data() {
    return {
      loadingDetails$: {},
      details$: {},
      number: 0,
      companies: [],
      customers: [],
      currentOrder: {},
      changingStatus: false,
      orderStatus: [
        {id: 'DRAFT', label: this.$t('ORDER_STATUS_DRAFT')},
        {id: 'IN_PROCESS', label: this.$t('ORDER_STATUS_IN_PROCESS')},
        {id: 'PROCESSED', label: this.$t('ORDER_STATUS_PROCESSED')},
        {id: 'CANCELLED_BY_USER', label: this.$t('ORDER_STATUS_CANCELLED_BY_USER')},
        {id: 'CANCELLED_BY_CUSTOMER', label: this.$t('ORDER_STATUS_CANCELLED_BY_CUSTOMER')}
      ]
    }
  },
  computed: {
    loadingDetails() {
      return this.loadingDetails$;
    },
    fields() {
      return this.ownOrders ?
          [
            {key: 'number', sortable: true, tdClass: 'customTd'},
            {key: 'date', sortable: true, tdClass: 'customTd'},
            {key: 'status', sortable: true, tdClass: 'customTd'},
            {key: 'total', tdClass: 'customTd'},
            {key: 'importDate', sortable: true, tdClass: 'customTd'},
            {key: 'observations', sortable: true, tdClass: 'customTd'},
            'options'
          ] :
          [
            {key: 'number', sortable: true, tdClass: 'customTd'},
            {key: 'date', sortable: true, tdClass: 'customTd'},
            {key: 'importDate', sortable: true, tdClass: 'customTd'},
            {key: 'importNumber', sortable: true, tdClass: 'customTd'},
            {key: 'series', sortable: true, tdClass: 'customTd'},
            {key: 'status', sortable: true, tdClass: 'customTd'},
            {key: 'total', tdClass: 'customTd'},
            {key: 'customer', sortable: true, tdClass: 'customTd'},
            {key: 'observations', sortable: true, tdClass: 'customTd'},
            'options'
          ];
    },
    ownOrders() {
      return this.$route.path.indexOf('my-orders') !== -1;
    }
  }
}
</script>

<style scoped>

</style>
