import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import BasePage from "@/views/BasePage";
import UserForm from "@/users/UserForm";
import UserList from "@/users/UserList";
import ProfileList from "@/users/profiles/ProfileList";
import ProfileForm from "@/users/profiles/ProfileForm";
import SessionList from "@/users/sessions/SessionList";
import UserProfile from "@/users/UserProfile";
import ProductList from "@/products/ProductList";
import OrderList from "@/orders/OrderList";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'LOGIN_PAGE_TITLE',
    component: Login
  },
  {
    path: '/users',
    component: BasePage,
    children: [
      {path: '', name: 'USERS_PAGE_TITLE', component: UserList},
      {path: 'register', name: 'USERS_REGISTER_TITLE', component: UserForm},
      {path: ':id/edit', name: 'USERS_EDIT_TITLE', component: UserForm}
    ]
  },
  {
    path: '/profile',
    component: BasePage,
    children: [
      {path: '', name: 'PROFILE_PAGE_TITLE', component: UserProfile},
    ]
  },
  {
    path: '/profiles',
    component: BasePage,
    children: [
      {path: '', name: 'PROFILES_PAGE_TITLE', component: ProfileList},
      {path: 'register', name: 'PROFILES_REGISTER_TITLE', component: ProfileForm},
      {path: ':id/edit', name: 'PROFILES_EDIT_TITLE', component: ProfileForm}
    ]
  },
  {
    path: '/sessions',
    component: BasePage,
    children: [
      {path: '', name: 'SESSIONS_PAGE_TITLE', component: SessionList}
    ]
  },
  {
    path: '/products',
    component: BasePage,
    children: [
      {path: '', name: 'PRODUCTS_PAGE_TITLE', component: ProductList}
    ]
  },
  {
    path: '/favorites',
    component: BasePage,
    children: [
      {path: '', name: 'FAVORITES_PAGE_TITLE', component: ProductList}
    ]
  },
  {
    path: '/orders',
    component: BasePage,
    children: [
      {path: '', name: 'ORDERS_PAGE_TITLE', component: OrderList}
    ]
  },
  {
    path: '/my-orders',
    component: BasePage,
    children: [
      {path: '', name: 'MY_ORDERS_PAGE_TITLE', component: OrderList}
    ]
  },
  {
    path: '',
    component: BasePage,
    children: [
      {path: '', name: 'PRODUCTS_PAGE_TITLE', component: ProductList}
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
