<template>
  <div>
    <div v-if="isBusy" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CUSTOMER_LOADING_LABEL') }}</strong>
    </div>
    <b-form-group v-else>
      <b-form-select :value="value" name="companies" @input="handleInput">
        <b-select-option v-if="showUndefined" :value="undefined">{{$t('ALL_CUSTOMERS_LABEL')}}</b-select-option>
        <b-select-option
            v-for="customer in customers"
            :key="customer.code"
            :value="customer.code">{{ customer.name }}
        </b-select-option>
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script>
import {LoadData, VModel} from "@/mixins";
import Customers from "@/customers/index";

export default {
  name: "SingleCustomerSelector",
  mixins: [VModel, LoadData],
  props: ['company', 'showUndefined'],
  watch: {
    company() {
      this.loadData();
    }
  },
  data() {
    return {
      customers: [],
      content: this.value,
      currentCompany: this.company
    }
  },
  methods: {
    loadData() {
      this.isBusy = true;
      Customers.findAll(this.company)
          .then(customers => this.customers = customers.data)
          .catch(e => console.error(e))
          .finally(() => {this.isBusy = false});
    },
  }
}
</script>

<style scoped>

</style>
