<template>
  <div>
    <div v-if="isBusy" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('USER_DATA_LOADING_LABEL') }}</strong>
    </div>
    <b-card v-else :title="$t('GENERAL_DATA_TITLE')">
      <b-card-text>
        <pre v-if="debug">{{ form }}</pre>
        <b-form @submit.prevent="save">
          <b-container fluid>
            <b-row>
              <b-col md="6" sm="12">
                <!-- username input -->
                <b-form-group
                    id="username-form-group"
                    :description="$t('USERNAME_INPUT_DESCRIPTION')"
                    :label="$t('USERNAME_LABEL')"
                    label-for="username-input"
                    :state="state('username')"
                >
                  <b-form-input
                      id="username-input"
                      :value="form.username"
                      @change="set('username', $event)"
                      :state="state('username')"></b-form-input>

                  <b-form-invalid-feedback id="username-input-feedback">
                    <div v-for="error in errors('username')" :key="error.error">
                      {{ $t('ERROR_USERNAME_' + error.error, error.params) }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12">
                <!-- email input -->
                <b-form-group
                    id="email-form-group"
                    :description="$t('EMAIL_INPUT_DESCRIPTION')"
                    :label="$t('EMAIL_LABEL')"
                    label-for="email-input"
                    :state="state('email')"
                >
                  <b-form-input id="email-input"
                                :value="form.email"
                                @change="set('email', $event)"
                                :state="state('email')"
                                trim></b-form-input>

                  <b-form-invalid-feedback id="email-input-feedback">
                    <div v-for="error in errors('email')" :key="error.error">
                      {{ $t('ERROR_EMAIL_' + error.error, error.params) }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" sm="12"> <!-- name input -->
                <b-form-group
                    id="name-form-group"
                    :description="$t('NAME_INPUT_DESCRIPTION')"
                    :label="$t('NAME_LABEL')"
                    label-for="name-input"
                    :state="state('name')"
                >
                  <b-form-input id="name-input" v-model="$v.form.name.$model" :state="state('name')"
                                trim></b-form-input>

                  <b-form-invalid-feedback id="name-input-feedback">
                    <div v-for="error in errors('name')" :key="error.error">
                      {{ $t('ERROR_NAME_' + error.error, error.params) }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12"><!-- lastName input -->
                <b-form-group
                    id="lastName-form-group"
                    :description="$t('LAST_NAME_INPUT_DESCRIPTION')"
                    :label="$t('LAST_NAME_LABEL')"
                    label-for="lastName-input"
                    :state="state('lastName')"
                >
                  <b-form-input id="lastName-input" v-model="$v.form.lastName.$model" :state="state('lastName')"
                                trim></b-form-input>

                  <b-form-invalid-feedback id="lastName-input-feedback">
                    <div v-for="error in errors('lastName')" :key="error.error">
                      {{ $t('ERROR_LAST_NAME_' + error.error, error.params) }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" sm="12"><!-- password input -->
                <b-form-group
                    id="password-form-group"
                    :description="$t('PASSWORD_INPUT_DESCRIPTION')"
                    :label="$t('PASSWORD_LABEL')"
                    label-for="password-input"
                    :state="state('password')"
                >
                  <b-form-input id="password-input" type="password" v-model="$v.form.password.$model"
                                :state="state('password')"
                                trim></b-form-input>

                  <b-form-invalid-feedback id="password-input-feedback">
                    <div v-for="error in errors('password')" :key="error.error">
                      {{ $t('ERROR_PASSWORD_' + error.error, error.params) }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="6" sm="12"> <!-- verification input -->
                <b-form-group
                    id="verification-form-group"
                    :description="$t('PASSWORD_VERIFICATION_INPUT_DESCRIPTION')"
                    :label="$t('PASSWORD_VERIFICATION_LABEL')"
                    label-for="verification-input"
                    :state="state('verification')"
                >
                  <b-form-input id="verification-input" type="password" v-model="$v.form.verification.$model"
                                :state="state('verification')"
                                trim></b-form-input>

                  <b-form-invalid-feedback id="verification-input-feedback">
                    <div v-for="error in errors('verification')" :key="error.error">
                      {{ $t('ERROR_PASSWORD_VERIFICATION_' + error.error, error.params) }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button type="submit" :disabled="saving" variant="primary">
                  <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
                  <span v-if="saving">{{ $t('SAVING_LABEL') }}</span>
                  <span v-else>{{ $t('SAVE_LABEL') }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </b-card-text>
    </b-card>

    <b-card class="mt-2" :title="$t('COMPANY_SELECT_LABEL')">
      <b-card-text>
        <single-company-selector v-model="form.company"></single-company-selector>
      </b-card-text>
    </b-card>

    <b-card v-if="form.company" class="mt-2" :title="$t('CUSTOMER_SELECT_LABEL')">
      <b-card-text>
        <single-customer-selector :company="form.company" v-model="form.customerCode"></single-customer-selector>
      </b-card-text>
    </b-card>

    <profile-list v-model="form.profiles" class="my-2"></profile-list>

    <permission-selector v-model="form.permissions"></permission-selector>
  </div>
</template>

<script>
import {email, maxLength, minLength, required, requiredIf, sameAs} from "vuelidate/lib/validators";
import ProfileList from "@/users/profiles/ProfileSelector";
import {Form} from "@/mixins";
import Users from "@/users";
import Profiles from "@/users/profiles";
import Permissions from "@/users/permissions";
import PermissionSelector from "@/users/permissions/PermissionSelector";
import SingleCompanySelector from "@/companies/SingleCompanySelector";
import SingleCustomerSelector from "@/customers/SingleCustomerSelector";

export default {
  name: "UserForm",
  components: {SingleCustomerSelector, SingleCompanySelector, PermissionSelector, ProfileList},
  mixins: [Form],
  methods: {
    create: Users.create,
    update: Users.update,
    async save() {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) {
          return;
        }
        this.saving = true;
        let resp;
        if (this.id) {
          console.log(this.form);
          if (!this.form.password) {
            delete this.form.password;
            delete this.form.verification;
          }
          console.log(this.form);
          resp = await this.update(this.id, this.form);
        } else {
          resp = await this.create(this.form);
        }

        this.sendMessage(this.id ? 'EVENT_USER_UPDATED' : 'EVENT_USER_CREATED', this.form);
        if (!this.id) {
          const idSplit = resp.data._links.self.href.split('/');
          const id = idSplit[idSplit.length - 1];
          this.$router.push('/users/' + id + '/edit').then();
        }
      } catch (e) {
        console.error(e);
        this.sendError(this.id ? 'ERROR_USER_UPDATED' : 'ERROR_USER_CREATED', this.form);
      } finally {
        this.saving = false;
      }
    },
    getData() {
      this.isBusy = true;
      Users.findById(this.id)
          .then(resp => {
            resp.data.password = '';
            resp.data.verification = '';
            this.originals.username = resp.data.username;
            this.originals.email = resp.data.email;
            this.form.username = resp.data.username;
            this.form.name = resp.data.name;
            this.form.lastName = resp.data.lastName;
            this.form.email = resp.data.email;
            this.form.company = resp.data.company;
            this.form.customerCode = resp.data.customerCode;
            this.form.customerName = resp.data.customerName;
            return Profiles.findByUserId(this.id)
          })
          .then(resp => {
            this.form.profiles = resp.data._embedded.profiles.map(x => x._links.self.href);
            return Permissions.findByUserId(this.id);
          })
          .then(resp => {
            this.form.permissions = resp.data._embedded.permissions.map(x => x._links.self.href);
          })
          .catch(e => {
            console.error(e);
            this.sendError('ERROR_LOADING_USER_DATA', e);
          })
          .finally(() => {
            this.isBusy = false;
          });
    },
  },
  data() {
    return {
      originals: {
        username: undefined,
        email: undefined
      },
      form: {
        username: '',
        password: '',
        verification: '',
        name: '',
        lastName: '',
        email: '',
        company: '',
        permissions: [],
        profiles: []
      }
    }
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(32),
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (!value || value === '') return true

          if (this.originals.username && this.originals.username === value) return true;

          // simulate async call, fail for all logins with even length
          return Users.usernameExists(value);
        }
      },
      password: {
        required: requiredIf(function () {
          return !this.id;
        }), minLength: minLength(4), maxLength: maxLength(32)
      },
      verification: {
        required: requiredIf(function () {
          return !this.id;
        }), minLength: minLength(4), maxLength: maxLength(32), sameAsPassword: sameAs('password')
      },
      name: {required, minLength: minLength(4), maxLength: maxLength(32)},
      lastName: {required, minLength: minLength(4), maxLength: maxLength(32)},
      email: {
        required,
        email,
        minLength: minLength(4),
        maxLength: maxLength(200)
      },
    }
  }
}
</script>

<style scoped>

</style>
