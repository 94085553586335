import http from "@/http";
import eventBus from "@/events";
import Constants from "@/constants";

const Orders = {
    findAll: async function (ctx) {
        const page = ctx.currentPage - 1 || 0;
        const size = ctx.perPage || 20;
        const sortDesc = ctx.sortDesc ? ',desc' : '';
        const sort = (ctx.sortBy || 'number') + sortDesc;
        const filter = ctx.filter ? ctx.filter : {};
        const pagination = {page, size, sort, sortDesc};
        const params = {...filter, ...pagination};
        const resp = await http("/orders/search/all", {params});
        eventBus.$emit(Constants.EVENT_ON_ORDER_LIST, resp.data.page.totalElements);
        return resp.data['_embedded']['orders'];
    },
    findById: async function(id) {
        return http.get('/orders/' + id);
    },
    deleteById: async function(id) {
        return http.delete('/orders/' + id);
    },
    changeStatus(id, status) {
        return http.patch('/orders/' + id + '/changeStatus', {status});
    },
    cancelOrder(id) {
        return http.patch('/users/cancelOrder?orderId=' + id);
    }
};

export default Orders;
