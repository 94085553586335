<template>
  <div>
    <div class="py-1">
      <b-button class="mx-2" @click="refresh" variant="info">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('USERS_REFRESH_LABEL') }}</span>
      </b-button>
    </div>

    <b-form>
      <b-container fluid>
        <b-row>
          <b-col>
            <b-form-group
                id="search-form-group"
                :description="$t('SESSION_SEARCH_INPUT_DESCRIPTION')"
                :label="$t('SESSION_SEARCH_LABEL')"
                label-for="search-input">
              <b-form-input id="search-input" lazy v-model="filter.text"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                id="search-form-group"
                :label="$t('SESSION_FROM_LABEL')"
                label-for="search-from">
              <b-input-group>
                <b-form-input
                    id="example-input"
                    v-model="filter.from"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                      v-model="filter.from"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="example-input"
                      @context="onContext"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

          </b-col>
          <b-col>
            <b-form-group
                id="search-form-group"
                :label="$t('SESSION_TO_LABEL')"
                label-for="search-to">
              <b-input-group>
                <b-form-input
                    id="example-input"
                    v-model="filter.to"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker button-only id="search-to" v-model="filter.to"></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>

            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                id="status-form-group"
                :label="$t('FILTER_SESSIONS_LABEL')"
                label-for="status-select">
              <b-form-select v-model="filter.status" id="status-select">
                <b-form-select-option :value="undefined">{{ $t('ALL_SESSIONS_LABEL') }}</b-form-select-option>
                <b-form-select-option :value="true">{{ $t('ACTIVE_SESSIONS_LABEL') }}</b-form-select-option>
                <b-form-select-option :value="false">{{ $t('INACTIVE_SESSIONS_LABEL') }}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-form>

    <b-table
        id="sessions-table"
        ref="sessionsTable"
        v-model="items"
        striped
        hover
        bordered
        small
        :current-page="page"
        :filter="filter"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :items="getSessions">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('SESSIONS_LOADING_LABEL') }}</strong>
        </div>
      </template>

      <template #head(username)="">
        <span>{{ $t('SESSION_USERNAME_LABEL') }}</span>
      </template>

      <template #cell(username)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.username }}</span>
        </div>
      </template>

      <template #head(ip)="">
        <span>{{ $t('SESSION_IP_LABEL') }}</span>
      </template>

      <template #cell(ip)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.ip }}</span>
        </div>
      </template>

      <template #head(type)="">
        <span>{{ $t('SESSION_TYPE_LABEL') }}</span>
      </template>

      <template #cell(type)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ $t(data.item.type + '_LABEL') }}</span>
        </div>
      </template>

      <template #head(enabled)="">
        <span>{{ $t('ENABLED_LABEL') }}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(userAgent)="">
        <span>{{ $t('USER_AGENT_LABEL') }}</span>
      </template>

      <template #cell(userAgent)="data">
        <div style="text-align: center">{{ data.item.userAgent }}</div>
      </template>

      <template #head(validThru)="">
        <span>{{ $t('SESSION_EXPIRATION_LABEL') }}</span>
      </template>

      <template #cell(validThru)="data">
        <div style="text-align: center">{{ data.item.validThru | date('DD/MM/YYYY HH:mm:ss') }}</div>
      </template>

      <template #head(options)="">
        <span>{{ $t('OPTIONS_LABEL') }}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown v-if="data.item.enabled" size="sm" id="dropdown-1" right class="m-md-2">
            <b-dropdown-item v-has-permission="'SESSION_DELETE_PERMISSION'" @click="disable(data.item.id)">
              {{ $t('SESSION_DISABLE_LABEL') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>

    <b-pagination
        v-model="page"
        :total-rows="totalRows"
        :per-page="size"
        aria-controls="sessions-table"
    ></b-pagination>
  </div>
</template>

<script>
import {DateFilter, Table} from "@/mixins";
import Sessions from "@/users/sessions/index";
import eventBus from "@/events";
import Constants from "@/constants";


export default {
  name: "SessionList",
  mixins: [Table, DateFilter],
  mounted() {
    eventBus.$on(Constants.EVENT_ON_SESSION_LIST, (totalElements) => {
      this.totalRows = totalElements;
    });
  },
  methods: {
    getSessions: Sessions.findAll,
    refresh: function () {
      this.$refs.sessionsTable.refresh()
    },
    disable: async function (id) {
      if (confirm(this.$t('CONFIRM_SESSION_DISABLE'))) {
        try {
          this.isBusy = true;
          await Sessions.disable(id);
          this.sendMessage('SESSION_DISABLED_SUCCESSFULLY', {name});
          this.getRow(id).enabled = false;
        } catch (e) {
          console.error(e);
          this.sendError('ERROR_DISABLING_SESSION', {name})
        } finally {
          this.isBusy = false;
        }
      }
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    }
  },
  computed: {
    fields() {
      return [
        {key: 'username', sortable: true, tdClass: 'customTd'},
        {key: 'ip', sortable: true, tdClass: 'customTd'},
        {key: 'type', sortable: true, tdClass: 'customTd'},
        {key: 'enabled', sortable: true, tdClass: 'customTd'},
        {key: 'userAgent', sortable: true, tdClass: 'customTd'},
        {key: 'validThru', sortable: true, tdClass: 'customTd'},
        'options'];
    }
  }
}
</script>

<style scoped>

</style>
