<template>
  <div>
    <b-navbar type="dark" variant="info">
      <b-button variant="info" v-b-toggle.menu-1>
        <b-icon-list></b-icon-list>
      </b-button>
      <b-navbar-brand class="ml-2">
        {{ company }}
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">

          <b-button :disabled="loadingCart" @click="openCart" variant="info" size="sm" class="my-2 my-sm-0" type="submit">
            <b-icon-cart class="mr-1" v-if="!loadingCart"></b-icon-cart>
            <b-badge v-if="!loadingCart" variant="danger">{{ order.details ? order.details.length : 0 }}</b-badge>
            <b-icon-circle-fill v-else animation="throb"></b-icon-circle-fill>
          </b-button>

          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>{{ user }}</em>
            </template>
            <b-dropdown-item @click="profile" href="#">{{ $t('PROFILE_LABEL') }}</b-dropdown-item>
            <b-dropdown-item @click="logout" href="#">{{ $t('LOGOUT_LABEL') }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-modal id="company-select-modal" :title="$t('COMPANY_SELECT_LABEL')" no-close-on-backdrop no-close-on-esc
             hide-footer>
      <b-form @submit.prevent="setCurrentCompany(selected)">
        <single-company-selector v-model="selected"></single-company-selector>
        <b-button type="submit" class="mt-2" variant="primary">{{ $t('COMPANY_OPEN_LABEL') }}</b-button>
      </b-form>
    </b-modal>

    <b-modal size="xl" id="cart-modal" :title="$t('CURRENT_ORDER_LABEL')" no-close-on-backdrop no-close-on-esc>
      <div v-if="loading" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-1">{{ $t('CART_LOADING_LABEL') }}</strong>
      </div>
      <div v-if="!loading && order.details.length <= 0">
        <b-alert :show="true" variant="warning">{{ $t('EMPTY_CART_MESSAGE') }}</b-alert>
      </div>
      <div v-else>
        <b-table-simple>
          <b-thead head-variant="info">
            <b-tr>
              <b-th>{{ $t('PRODUCT_LABEL') }}</b-th>
              <b-th>{{ $t('QUANTITY_LABEL') }}</b-th>
              <b-th>{{ $t('UNIT_PRICE_LABEL') }}</b-th>
              <b-th>{{ $t('SUBTOTAL_LABEL') }}</b-th>
              <b-th>{{ $t('OPTIONS_LABEL') }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(detail, idx) in order.details" :key="detail.productCode">
              <b-td>
                {{ detail.productName }}
              </b-td>
              <b-td>
                {{ detail.quantity | number }} {{ detail.measure_unit }}
              </b-td>
              <b-td>
                <span v-if="detail.diff === 0">{{ detail.price | currency }}</span>
                <span
                    v-if="detail.diff > 0"
                    class="text-danger"
                    v-b-tooltip
                    :title="$t('PRICE_WENT_UP', {oldPrice: detail.price, newPrice: detail.price - detail.diff})"
                >{{ detail.price | currency }}</span>
                <span
                    v-if="detail.diff < 0"
                    class="text-success"
                    v-b-tooltip
                    :title="$t('PRICE_WENT_DOWN', {oldPrice: detail.price, newPrice: detail.price - detail.diff})"
                >{{ detail.price | currency }}</span>
              </b-td>
              <b-td>
                {{ detail.total | currency }}
              </b-td>
              <b-td>
                <b-button variant="danger" @click="removeFromCart(detail.productCode, idx)">
                  {{ $t('REMOVE_LABEL') }}
                  <b-icon-x-circle></b-icon-x-circle>
                </b-button>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td colspan="4">Total: </b-td>
              <b-td class="text-right">{{ total() | currency }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <b-form-group v-if="showNotes" :label="$t('ORDER_OBSERVATIONS_LABEL')">
          <b-textarea rows="10" v-model="order.observations"></b-textarea>
        </b-form-group>
      </div>

      <template #modal-footer>
        <b-button class="mr-2" variant="primary" :disabled="sendingOrder" v-if="order.details.length > 0"
                  @click="sendOrder">
          <b-icon-circle-fill animation="throb" v-if="sendingOrder"></b-icon-circle-fill>
          {{ $t('SEND_ORDER_LABEL') }}
        </b-button>
        <b-button variant="danger" :disabled="emptyingCart" @click="emptyCart" v-if="order.details.length > 0">
          <b-icon-circle-fill animation="throb" v-if="emptyingCart"></b-icon-circle-fill>
          {{ $t('EMPTY_CART_LABEL') }}
        </b-button>
      </template>
    </b-modal>
  </div>

</template>

<script>
import Users from "@/users";
import {LoadData, NumberFilter, SendsMessages} from "@/mixins";
import Companies from "@/companies";
import SingleCompanySelector from "@/companies/SingleCompanySelector";
import eventBus from "@/events";
import Constants from "@/constants";

export default {
  name: "app-menu",
  components: {SingleCompanySelector},
  mixins: [LoadData, SendsMessages, NumberFilter],
  mounted() {
    eventBus.$on(Constants.WHEN_COMPANY_SELECTED, () => {
      this.loadCart();
    });

    eventBus.$on(Constants.EVENT_WHEN_ADDED_TO_CART, () => {
      if (this.loadCart) {
        this.loadCart();
      }
    });
  },
  methods: {
    loadCart() {
      this.loadingCart = true;
      Users.getCart().then(resp => {
        this.order = resp.data || {details: []};
      }).catch((e) => this.sendError('ERROR_LOADING_CART', {}, e))
          .finally(() => this.loadingCart = false)
    },
    openCart() {
      this.$bvModal.show('cart-modal');
    },
    closeCart() {
      this.$bvModal.hide('cart-modal');
    },
    setCurrentCompany(company) {
      this.$bvModal.hide('company-select-modal')
      Companies.setCurrentCompany(company);
      eventBus.$emit(Constants.WHEN_COMPANY_SELECTED, company);
      this.loadCompanyData(company);
    },
    changeCompany() {
      this.$bvModal.show('company-select-modal');
    },
    loadData() {
      Users.getCurrentUser().then(resp => {
        Users.currentUser = resp.data;
        this.user = resp.data.fullName;
        if (resp.data.company) {
          Companies.setCurrentCompany(resp.data.company);
          eventBus.$emit(Constants.WHEN_COMPANY_SELECTED, resp.data.company);
          this.loadCompanyData(resp.data.company);
        } else {
          this.sendError(this.$t('NO_COMPANY_AVAILABLE_MESSAGE', {username: resp.data.username}));
        }
      }).catch(e => this.sendError(this.$t('ERROR_WHILE_SETTING_CURRENT_COMPANY', {}, e)));
    },
    loadCompanyData(database) {
      Companies.findByDatabase(database).then(resp => {
        this.company = resp.data.name;
      })
          .catch(e => this.sendError('ERROR_GETTING_COMPANY_DATA', {database}, e))
          .finally();
    },
    async logout() {
      await Users.logout();
      await this.$router.push({path: '/login'})
    },
    profile() {
      this.$router.push({path: '/profile'});
    },
    total() {
      if(this.order.details && this.order.details.length > 0) {
        return this.order.details.map(x => x.quantity * x.price).reduce((a,b) => a + b);
      }
    },
    async emptyCart() {
      try {
        if (confirm(this.$t('CONFIRM_EMPTYING_CART'))) {
          this.emptyingCart = true;
          await Users.emptyCart();
          this.order.details = [];
          this.closeCart();
          this.sendMessage('SUCCESSFULLY_EMPTY_CART');
        }
      } catch (e) {
        this.sendError('ERROR_EMPTY_CART', {}, e);
      } finally {
        this.emptyingCart = false;
      }
    },
    async removeFromCart(code, idx) {
      try {
        this.loading = true;
        await Users.removeFromCart(code);
        this.order.details.splice(idx, 1);
        this.sendMessage('SUCCESSFULLY_REMOVED_PRODUCT', {product: code});
      } catch (e) {
        this.sendError('ERROR_REMOVING_PRODUCT', {product: code}, e);
      } finally {
        this.loading = false;
      }
    },
    removeFromCartUI(code) {
      if (this.order.details && this.order.details.length > 0) {
        const idx = this.order.details.map(x => x.productCode === code).indexOf(code);
        this.order.details.splice(idx, 1);
      }
    },
    async sendOrder() {
      try {
        if(!this.showNotes) {
          this.showNotes = true;
          return;
        }
        this.sendingOrder = true;
        const order = await Users.sendCart(this.order.observations);
        this.order.details = [];
        this.closeCart();
        console.log(order.data);
        this.sendMessage('SUCCESSFULLY_SENT_ORDER', order.data);
        eventBus.$emit(Constants.WHEN_ORDER_CREATED, order.data);
      } catch (e) {
        this.sendError('ERROR_SENDING_ORDER', {}, e);
      } finally {
        this.sendingOrder = false;
      }
    }
  },
  data() {
    return {
      loading: false,
      user: this.$t('NO_USER_LABEL'),
      company: '',
      selected: '',
      loadingCart: false,
      emptyingCart: false,
      sendingOrder: false,
      showNotes: false,
      order: {details: []}
    }
  }
}
</script>

<style scoped>

</style>
