<template>
  <b-sidebar id="menu-1" :title="$t('MENU_LABEL')" shadow backdrop backdrop-variant="dark">
    <b-list-group>
      <b-list-group-item v-has-permission="'USER_LIST_ALL_PERMISSION'" to="/users">
        <b-icon-person></b-icon-person>
        {{$t('USERS_PAGE_TITLE')}}
      </b-list-group-item>
      <b-list-group-item v-has-permission="'PROFILE_LIST_ALL_PERMISSION'" to="/profiles">
        <b-icon-file-earmark-person></b-icon-file-earmark-person>
        {{$t('PROFILES_PAGE_TITLE')}}
      </b-list-group-item>
      <b-list-group-item v-has-permission="'SESSION_LIST_ALL_PERMISSION'" to="/sessions">
        <b-icon-key></b-icon-key>
        {{$t('SESSIONS_PAGE_TITLE')}}
      </b-list-group-item>
      <b-list-group-item v-has-permission="'ORDER_LIST_ALL_PERMISSION'" to="/orders">
        <b-icon-basket></b-icon-basket>
        {{$t('ORDERS_PAGE_TITLE')}}
      </b-list-group-item>
      <b-list-group-item to="/products">
        <b-icon-box-seam></b-icon-box-seam>
        {{$t('PRODUCTS_PAGE_TITLE')}}
      </b-list-group-item>
      <b-list-group-item to="/favorites">
        <b-icon-star></b-icon-star>
        {{$t('FAVORITES_PAGE_TITLE')}}
      </b-list-group-item>
      <b-list-group-item to="/my-orders">
        <b-icon-basket></b-icon-basket>
        {{$t('MY_ORDERS_PAGE_TITLE')}}
      </b-list-group-item>
    </b-list-group>
  </b-sidebar>
</template>

<script>
import {Security} from "@/mixins";

export default {
  name: "AppSidebar",
  mixins: [Security]
}
</script>

<style scoped>

</style>
