<template>
  <b-container fluid>
    <b-row>
      <b-col offset="3" md="6">
        <h1>{{ $t('LOGIN_PAGE_TITLE') }}</h1>
        <b-form @submit.prevent="login">
          <b-form-group id="username" :label="$t('USERNAME_LABEL')">
            <b-form-input v-model.trim="$v.form.username.$model" :state="state('username')"></b-form-input>
          </b-form-group>
          <b-form-group id="password" :label="$t('PASSWORD_LABEL')">
            <b-form-input type="password" v-model.trim="$v.form.password.$model" :state="state('password')"></b-form-input>
          </b-form-group>
          <b-button :disabled="$v.form.$invalid" variant="primary" type="submit">{{ $t('LOGIN_BUTTON_LABEL') }}
          </b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {minLength, required} from "vuelidate/lib/validators";
import Users from "@/users";
import Utils from "@/utils";
import {SendsMessages} from "@/mixins";

export default {
  name: "Login",
  mixins: [SendsMessages],
  validations: {
    form: {
      username: {required, minLength: minLength(4)},
      password: {required, minLength: minLength(4)},
    }
  },
  data() {
    return {
      form: {
        username: undefined,
        password: undefined
      }
    }
  },
  methods: {
    login: async function () {
      try {
        await Users.login(this.form.username, this.form.password);
        await this.$router.push({path: '/products'});
      } catch (e) {
        this.sendError('ERROR_INVALID_CREDENTIALS', {}, e);
      }
    }
  },
  computed: {
    // state() {
    //   return function (field) {
    //     return this.$v.form[field].$dirty ? !this.$v.form[field].$invalid : null;
    //   }
    // }
    state() {
      return Utils.state(this.$v);
    }
  }
}
</script>

<style scoped>

</style>
