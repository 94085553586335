import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {BootstrapVue, IconsPlugin, BootstrapVueIcons } from "bootstrap-vue";
import i18n from "@/i18n";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {Vuelidate} from "vuelidate";
import './global.css';
import {hasPermission} from "@/directives";

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);

Vue.directive('hasPermission', hasPermission)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
