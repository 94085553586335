<template>
  <div>
    <div v-if="isBusy" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('PERMISSION_LOADING_LABEL') }}</strong>
    </div>
    <b-card v-else :title="$t('PERMISSIONS_TITLE')">
      <b-card-text>
        <b-form class="mb-3">
          <b-input :placeholder="$t('SEARCH_PERMISSION_PLACEHOLDER')" v-model="search"></b-input>
        </b-form>

        <b-form-checkbox-group
            id="permissions"
            v-model="value"
            name="permissions">
          <b-list-group>
            <b-list-group-item :key="permission.id"
                               v-for="permission in permissions.filter(x => search === '' || x.authority.indexOf(search) !== -1)">
              <b-form-checkbox
                  @change="handleInput"
                  style="display: block"
                  :value="permission._links.self.href">
                <b-badge variant="primary">{{permission.category}}</b-badge> {{ permission.authority }}
              </b-form-checkbox>
            </b-list-group-item>
          </b-list-group>
        </b-form-checkbox-group>

      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {LoadData, VModel} from "@/mixins";
import Permissions from '@/users/permissions';

export default {
  name: "PermissionSelector",
  mixins: [LoadData, VModel],
  methods: {
    async loadData() {
      this.isBusy = true;
      try {
        this.permissions = await Permissions.findAll();
        this.permissions.forEach(permission => {
          permission.authority = this.$t(permission.authority);
          permission.category = this.$t(permission._embedded.category.name);
        });
        this.permissions = this.permissions.sort((a,b) => a.category < b.category ? -1 : 1);
      } catch (e) {
        console.error(e);
      } finally {
        this.isBusy = false;
      }
    },
  },
  data() {
    return {
      search: '',
      permissions: [],
      content: this.value,
    }
  },
}
</script>

<style scoped>

</style>
