import http from '@/http';
import eventBus from '@/events';
import Constants from '@/constants';
import jwt_decode from 'jwt-decode';

const TOKEN_STORAGE_KEY = 'authentication-token';
const TOKEN_HTTP_HEADER = 'Authorization';
const AUTH_CHECK_INTERVAL = 1000 * 60; // milliseconds

const Token = {
    accessToken: undefined,
    setToken: function (strAccessToken) {
        this.accessToken = jwt_decode(strAccessToken);
        localStorage.setItem(TOKEN_STORAGE_KEY, strAccessToken);
        http.defaults.headers.common[TOKEN_HTTP_HEADER] = 'Bearer ' + strAccessToken;
        eventBus.$emit(Constants.EVENT_WHEN_USER_COMES_BACK, this.accessToken.userId);
        this.interval = setInterval(() => {
            if (Date.now() < this.accessToken.exp * 1000) {
                eventBus.$emit(Constants.EVENT_WHEN_AUTHENTICATION_EXPIRES);
            }
        }, AUTH_CHECK_INTERVAL);
    },
    removeToken: function () {
        this.accessToken = undefined;
        localStorage.removeItem(TOKEN_STORAGE_KEY);
        delete http.defaults.headers.common[TOKEN_HTTP_HEADER];
        this.interval = null;
    },
    loadFromStorage() {
        const tokenString = localStorage.getItem(TOKEN_STORAGE_KEY);
        if (tokenString) {
            this.setToken(tokenString);
        }
    },
    isAuthenticated() {
        this.loadFromStorage();
        return !!this.accessToken;
    },
    isNotAuthenticated() {
        return !this.isAuthenticated();
    },
    hasPermission(permission) {
        const idx = this.isAuthenticated() ? this.accessToken.authorities.indexOf(permission) : -1;
        return idx !== -1;
    },
    interval: undefined
}

export default Token;
