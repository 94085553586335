const Constants = {
    DEBUG: false,
    CURRENCY_FORMAT: '$ 0,0.0000',
    NUMBER_FORMAT: '0,0.0000',
    DATE_FORMAT: 'DD/MM/YYYY',
    PERCENTAGE_FORMAT: '0.000%',
    EVENT_WHEN_USER_COMES_BACK: 'EVENT_WHEN_USER_COMES_BACK',
    EVENT_WHEN_AUTHENTICATION_EXPIRES: 'EVENT_WHEN_AUTHENTICATION_EXPIRES',
    EVENT_INVALID_CREDENTIALS: 'EVENT_INVALID_CREDENTIALS',
    EVENT_COULD_NOT_GET_CURRENT_USER: 'EVENT_COULD_NOT_GET_CURRENT_USER',
    EVENT_COULD_NOT_GET_QUOTES: 'EVENT_COULD_NOT_GET_QUOTES',
    EVENT_COULD_NOT_GET_COMPANIES: "EVENT_COULD_NOT_GET_COMPANIES",
    EVENT_WHEN_NO_COMPANIES: 'EVENT_WHEN_NO_COMPANIES',
    EVENT_WHEN_COMPANY_CHANGES: "EVENT_WHEN_COMPANY_CHANGES",
    EVENT_WHEN_REQUEST_COMPANY_CHANGE: "EVENT_WHEN_REQUEST_COMPANY_CHANGE",
    EVENT_WHEN_DATA_SAVED: 'EVENT_WHEN_DATA_SAVED',
    EVENT_WHEN_MESSAGE_DISPLAY: 'EVENT_WHEN_MESSAGE_DISPLAY',
    EVENT_WHEN_LOGOUT: 'EVENT_WHEN_LOGOUT',
    EVENT_ON_USER_LIST: 'EVENT_ON_USER_LIST',
    EVENT_ON_PROFILE_LIST: 'EVENT_ON_PROFILE_LIST',
    EVENT_ON_SESSION_LIST: 'EVENT_ON_SESSION_LIST',
    EVENT_ON_COMPANY_LIST: 'EVENT_ON_COMPANY_LIST',
    WHEN_COMPANY_SELECTED: 'WHEN_COMPANY_SELECTED',
    WHEN_GLOBAL_SEARCH: 'WHEN_GLOBAL_SEARCH',
    //baseUrl: 'http://localhost:8080/api',
    //baseUrl: 'http://187.188.156.121:9045/api',
    //baseUrl: 'http://189.158.136.230:8080/api',
    baseUrl: 'https://api.foodcomwok.com:9045/api',
    EVENT_WHEN_ADDED_TO_CART: 'EVENT_WHEN_ADDED_TO_CART',
    EVENT_ON_ORDER_LIST: 'EVENT_ON_ORDER_LIST',
    WHEN_ORDER_CREATED: 'WHEN_ORDER_CREATED'
};

export default Constants;
