import http from "@/http";
import eventBus from "@/events";
import Constants from "@/constants";

const Profiles = {
    findAll: async function (ctx) {
        const page = ctx.currentPage - 1 || 0;
        const size = ctx.perPage || 20;
        const sort = ctx.sort || 'name';
        const sortDesc = ctx.sortDesc ? 'desc' : 'asc';
        const filter = ctx.filter ? ctx.filter : {};
        const pagination = {page, size, sort, sortDesc};
        const params = {...filter, ...pagination};
        const resp = await http("/profiles/search/all", {params});
        eventBus.$emit(Constants.EVENT_ON_PROFILE_LIST, resp.data.page.totalElements);
        return resp.data._embedded.profiles;
    },
    create: function(data) {
        return http.post('/profiles', data);
    },
    update: function (id, data) {
        return http.patch('/profiles/' + id, data);
    },
    findByUserId: function(id) {
        return http.get('/users/' + id + '/profiles');
    },
    nameExists(name) {
        return http.get("/profiles/exists/name", {params: {name: name}}).then(x => {
            return !x.data;
        });
    },
    findById(id) {
        return http.get('/profiles/' + id);
    },
    enable(id) {
        return http.patch('/profiles/' + id + '/enable')
    },
    disable(id) {
        return http.patch('/profiles/' + id + '/disable')
    },
    deleteById(id) {
        return http.delete('/profiles/' + id)
    }
};

export default Profiles;
