<template>
  <div>
    <div v-if="isBusy" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('COMPANY_LOADING_LABEL') }}</strong>
    </div>
    <b-form-group v-else>
      <b-form-select :value="value" name="companies" @input="handleInput">
        <b-select-option v-if="showUndefined" :value="undefined">{{$t('ALL_COMPANIES_LABEL')}}</b-select-option>
        <b-select-option
            v-for="company in companies"
            :key="company.database"
            :value="company.database">{{ company.name }}
        </b-select-option>
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script>
import http from "@/http";
import {LoadData, VModel} from "@/mixins";

export default {
  name: "SingleCompanySelector",
  mixins: [VModel, LoadData],
  props: ['showUndefined'],
  data() {
    return {
      companies: [],
      content: this.value
    }
  },
  methods: {
    loadData() {
      this.isBusy = true;
      return http
          .get('/companies')
          .then(resp => this.companies = resp.data)
          .finally(() => this.isBusy = false)
    },
  }
}
</script>

<style scoped>

</style>
