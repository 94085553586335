<template>
  <div>
    <div class="pb-3">
      <b-button v-has-permission="'USER_CREATE_PERMISSION'" @click="add()" variant="info">
        <b-icon-plus-circle></b-icon-plus-circle>
        <span class="ml-1">{{ $t('USERS_ADD_LABEL') }}</span>
      </b-button>

      <b-button class="ml-2" @click="refresh" variant="info">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('USERS_REFRESH_LABEL') }}</span>
      </b-button>
    </div>

    <b-form>
      <b-container fluid>
        <b-row>
          <b-col md="9">
            <b-form-group
                id="search-form-group"
                :description="$t('USER_SEARCH_INPUT_DESCRIPTION')"
                :label="$t('USER_SEARCH_LABEL')"
                label-for="search-input">
              <b-form-input id="search-input" lazy v-model="filter.text"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                id="status-form-group"
                :label="$t('FILTER_USERS_LABEL')"
                label-for="status-select"
            >
              <b-form-select v-model="filter.status" id="status-select">
                <b-form-select-option :value="undefined">{{$t('ALL_USERS_LABEL')}}</b-form-select-option>
                <b-form-select-option :value="true">{{$t('ACTIVE_USERS_LABEL')}}</b-form-select-option>
                <b-form-select-option :value="false">{{$t('INACTIVE_USERS_LABEL')}}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
    <b-table
        id="users-table"
        ref="usersTable"
        striped
        hover
        bordered
        small
        v-model="items"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :filter="filter"
        :items="getUsers">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('USER_LOADING_LABEL') }}</strong>
        </div>
      </template>

      <template #head(username)="">
        <span>{{ $t('USERNAME_LABEL') }}</span>
      </template>

      <template #cell(username)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.username }}</span>
        </div>
      </template>

      <template #head(fullName)="">
        <span>{{ $t('FULL_NAME_LABEL') }}</span>
      </template>

      <template #cell(fullName)="data">
        <span>{{ data.item.fullName }}</span>
      </template>

      <template #head(email)="">
        <span>{{ $t('EMAIL_LABEL') }}</span>
      </template>

      <template #cell(date)="data">
        <span>{{ data.item.email }}</span>
      </template>

      <template #head(enabled)="">
        <span>{{ $t('ENABLED_LABEL') }}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(options)="">
        <span>{{ $t('OPTIONS_LABEL') }}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown size="sm" id="dropdown-1" right class="m-md-2">
            <b-dropdown-item v-has-permission="'USER_UPDATE_PERMISSION'" @click="edit(data.item.id)">{{ $t('USER_EDIT_LABEL') }}</b-dropdown-item>
            <b-dropdown-item v-has-permission="'USER_DELETE_PERMISSION'" @click="remove(data.item.id, data.item.username)">{{ $t('USER_DELETE_LABEL') }}</b-dropdown-item>
            <b-dropdown-item v-has-permission="'USER_PASSWORD_CHANGE_PERMISSION'" @click="openChangePassword(data.item.id, data.item.username)">{{ $t('USER_PASSWORD_CHANGE_LABEL') }}</b-dropdown-item>
            <b-dropdown-item v-has-permission="'USER_DISABLE_PERMISSION'" @click="disable(data.item.id, data.item.username)" v-if="data.item.enabled">{{ $t('USER_DISABLE_LABEL') }}</b-dropdown-item>
            <b-dropdown-item v-has-permission="'USER_ENABLE_PERMISSION'" @click="enable(data.item.id, data.item.username)" v-else>{{ $t('USER_ENABLE_LABEL') }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>

    <b-pagination
        v-model="page"
        :total-rows="totalRows"
        :per-page="size"
        aria-controls="users-table"
    ></b-pagination>

    <b-modal id="user-change-password-modal" :title="$t('USER_CHANGE_PASSWORD', {username})" hide-footer>
      <user-change-password
          :user-id="id"
          @password-changed="closeChangePassword()">
      </user-change-password>
    </b-modal>
  </div>
</template>

<script>
import {Form, Table} from "@/mixins";
import Users from "@/users/index";
import UserChangePassword from "@/users/UserChangePassword";
import eventBus from "@/events";
import Constants from "@/constants";

export default {
  name: "UserList",
  components: {UserChangePassword},
  mixins: [Table, Form],
  mounted() {
    eventBus.$on(Constants.EVENT_ON_USER_LIST, (totalElements) => {
      this.totalRows = totalElements;
    });
  },
  methods: {
    getUsers: Users.findAll,
    refresh: function() {
      this.$refs.usersTable.refresh()
    },
    add: function () {
      this.$router.push('users/register');
    },
    edit: function(id) {
      this.$router.push('users/' + id + '/edit');
    },
    remove: async function(id, username) {
      if(confirm(this.$t('CONFIRM_USER_DELETE', {username}))) {
        try {
          this.isBusy = true;
          await Users.deleteById(id);
          this.sendMessage('USER_DELETED_SUCCESSFULLY', {username});
          this.removeRow(id);
        } catch (e) {
          console.error(e);
          this.sendError('ERROR_DELETING_USER', {username})
        } finally {
          this.isBusy = false;
        }
      }
    },
    enable: async function(id, username) {
      try {
        this.isBusy = true;
        console.log(this.items);
        await Users.enable(id);
        this.sendMessage('USER_ENABLED_SUCCESSFULLY', {username});
        this.getRow(id).enabled = true;
      } catch (e) {
        console.error(e);
        this.sendError('ERROR_ENABLING_USER', {username})
      } finally {
        this.isBusy = false;
      }
    },
    disable: async function(id, username) {
      try {
        this.isBusy = true;
        await Users.disable(id);
        this.sendMessage('USER_DISABLED_SUCCESSFULLY', {username});
        this.getRow(id).enabled = false;
      } catch (e) {
        console.error(e);
        this.sendError('ERROR_DISABLING_USER', {username})
      } finally {
        this.isBusy = false;
      }
    },
    openChangePassword: async function(id, username) {
      this.id = id;
      this.username = username;
      this.$bvModal.show('user-change-password-modal');
    },
    closeChangePassword: async function() {
      this.$bvModal.hide('user-change-password-modal');
      this.sendMessage('USER_PASSWORD_CHANGED', {username: this.username});
    }
  },
  data() {
    return {
      username: ''
    }
  },
  computed: {
    fields() {
      return [
        {key: 'username', sortable: true, tdClass: 'customTd'},
        {key: 'fullName', sortable: true, tdClass: 'customTd'},
        {key: 'email', sortable: true, tdClass: 'customTd'},
        {key: 'enabled', sortable: true, tdClass: 'customTd'},
        'options'];
    }
  }
}
</script>

<style scoped>

</style>
