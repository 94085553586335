<template>
  <div>
    <div class="py-1">
      <b-button v-has-permission="'PROFILE_CREATE_PERMISSION'" @click="add()" variant="info">
        <b-icon-plus-circle></b-icon-plus-circle>
        {{ $t('PROFILES_ADD_LABEL') }}
      </b-button>

      <b-button class="mx-2" @click="refresh" variant="info">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('USERS_REFRESH_LABEL') }}</span>
      </b-button>
    </div>

    <b-form>
      <b-container fluid>
        <b-row>
          <b-col md="9">
            <b-form-group
                id="search-form-group"
                :description="$t('PROFILE_SEARCH_INPUT_DESCRIPTION')"
                :label="$t('PROFILE_SEARCH_LABEL')"
                label-for="search-input">
              <b-form-input id="search-input" lazy v-model="filter.text"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                id="status-form-group"
                :label="$t('FILTER_PROFILES_LABEL')"
                label-for="status-select">
              <b-form-select v-model="filter.status" id="status-select">
                <b-form-select-option :value="undefined">{{$t('ALL_PROFILES_LABEL')}}</b-form-select-option>
                <b-form-select-option :value="true">{{$t('ACTIVE_PROFILES_LABEL')}}</b-form-select-option>
                <b-form-select-option :value="false">{{$t('INACTIVE_PROFILES_LABEL')}}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-form>

    <b-table
        id="profiles-table"
        ref="profilesTable"
        v-model="items"
        striped
        hover
        bordered
        small
        :filter="filter"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :items="getProfiles">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('PROFILE_LOADING_LABEL') }}</strong>
        </div>
      </template>

      <template #head(name)="">
        <span>{{ $t('PROFILE_NAME_LABEL') }}</span>
      </template>

      <template #cell(name)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.name }}</span>
        </div>
      </template>

      <template #head(enabled)="">
        <span>{{ $t('ENABLED_LABEL') }}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(options)="">
        <span>{{ $t('OPTIONS_LABEL') }}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown size="sm" id="dropdown-1" right class="m-md-2">
            <b-dropdown-item v-has-permission="'PROFILE_UPDATE_PERMISSION'" @click="edit(data.item.id)">{{ $t('PROFILE_EDIT_LABEL') }}</b-dropdown-item>
            <b-dropdown-item v-has-permission="'PROFILE_DELETE_PERMISSION'" @click="remove(data.item.id, data.item.name)">{{ $t('PROFILE_DELETE_LABEL') }}</b-dropdown-item>
            <b-dropdown-item v-has-permission="'PROFILE_DISABLE_PERMISSION'" @click="disable(data.item.id, data.item.name)" v-if="data.item.enabled">{{ $t('PROFILE_DISABLE_LABEL') }}</b-dropdown-item>
            <b-dropdown-item v-has-permission="'PROFILE_ENABLE_PERMISSION'" @click="enable(data.item.id, data.item.name)" v-else>{{ $t('PROFILE_ENABLE_LABEL') }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>

    <b-pagination
        v-model="page"
        :total-rows="totalRows"
        :per-page="size"
        aria-controls="profiles-table"
    ></b-pagination>
  </div>
</template>

<script>
import {Table} from "@/mixins";
import Profiles from "@/users/profiles";
import eventBus from "@/events";
import Constants from "@/constants";

export default {
  name: "ProfileList",
  mixins: [Table],
  mounted() {
    eventBus.$on(Constants.EVENT_ON_PROFILE_LIST, (totalElements) => {
      this.totalRows = totalElements;
    });
  },
  methods: {
    getProfiles: Profiles.findAll,
    refresh: function() {
      this.$refs.profilesTable.refresh()
    },
    add: function () {
      this.$router.push('profiles/register');
    },
    edit: function(id) {
      this.$router.push('profiles/' + id + '/edit');
    },
    remove: async function(id, name) {
      if(confirm(this.$t('CONFIRM_PROFILE_DELETE', {name}))) {
        try {
          this.isBusy = true;
          await Profiles.deleteById(id);
            this.sendMessage('PROFILE_DELETED_SUCCESSFULLY', {name});
          this.removeRow(id);
        } catch (e) {
          console.error(e);
          this.sendError('ERROR_DELETING_PROFILE', {name})
        } finally {
          this.isBusy = false;
        }
      }
    },
    enable: async function(id, name) {
      try {
        this.isBusy = true;
        await Profiles.enable(id);
        this.sendMessage('PROFILE_ENABLED_SUCCESSFULLY', {name});
        this.getRow(id).enabled = true;
      } catch (e) {
        console.error(e);
        this.sendError('ERROR_ENABLING_PROFILE', {name})
      } finally {
        this.isBusy = false;
      }
    },
    disable: async function(id, name) {
      try {
        this.isBusy = true;
        await Profiles.disable(id);
        this.sendMessage('PROFILE_DISABLED_SUCCESSFULLY', {name});
        this.getRow(id).enabled = false;
      } catch (e) {
        console.error(e);
        this.sendError('ERROR_DISABLING_PROFILE', {name})
      } finally {
        this.isBusy = false;
      }
    },
  },
  computed: {
    fields() {
      return [
        {key: 'name', sortable: true, tdClass: 'customTd'},
        {key: 'enabled', sortable: true, tdClass: 'customTd'},
        'options'];
    }
  }
}
</script>

<style scoped>

</style>
