import http from "@/http";

const Companies = {
    company: undefined,
    findAll: async function () {
        return http.get('/companies');
    },
    setCurrentCompany(company) {
        this.company = company;
    },
    findByDatabase(database) {
        return http.get('/companies/' + database);
    },
    close() {
        this.company = undefined
    }
};

export default Companies;
