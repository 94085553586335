<template>
  <div>
    <div v-if="isBusy" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('PROFILE_DATA_LOADING_LABEL') }}</strong>
    </div>
    <b-card v-else :title="$t('GENERAL_DATA_TITLE')" class="mb-2">
      <b-card-text>
        <pre v-if="debug">{{ form }}</pre>
        <b-form @submit.prevent="save">
          <!-- name input -->
          <b-form-group
              id="name-form-group"
              :description="$t('PROFILE_NAME_INPUT_DESCRIPTION')"
              :label="$t('PROFILE_NAME_LABEL')"
              label-for="name-input"
              :state="state('name')"
          >
            <b-form-input id="name-input" v-model="$v.form.name.$model" :state="state('name')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="name-input-feedback">
              <div v-for="error in errors('name')" :key="error.error">
                {{ $t('ERROR_PROFILE_NAME_' + error.error, error.params) }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-button type="submit" :disabled="saving" variant="primary">
            <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
            <span v-if="saving">{{ $t('SAVING_LABEL') }}</span>
            <span v-else>{{ $t('SAVE_LABEL') }}</span>
          </b-button>
        </b-form>
      </b-card-text>
    </b-card>

    <permission-categories v-model="form.permissions"></permission-categories>
  </div>
</template>

<script>
import PermissionCategories from "@/users/permissions/PermissionSelector";
import {Form} from "@/mixins";
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import Profiles from "@/users/profiles";
import Permissions from "@/users/permissions";

export default {
  name: "ProfileForm",
  components: {PermissionCategories},
  mixins: [Form],
  methods: {
    create: Profiles.create,
    update: Profiles.update,
    async save() {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) {
          return;
        }
        this.saving = true;
        const resp = this.id ? await this.update(this.id, this.form) : await this.create(this.form);
        this.sendMessage(this.id ? 'EVENT_PROFILE_UPDATED' : 'EVENT_PROFILE_CREATED', this.form);
        if (!this.id) {
          const idSplit = resp.data._links.self.href.split('/');
          const id = idSplit[idSplit.length - 1];
          this.$router.push('/profiles/' + id + '/edit').then();
        }
      } catch (e) {
        console.error(e);
        this.sendError(this.id ? 'ERROR_PROFILE_UPDATED' : 'ERROR_PROFILE_CREATED', this.form);
      } finally {
        this.saving = false;
      }
    },
    getData() {
      this.isBusy = true;
      Profiles.findById(this.id)
          .then(resp => {
            this.form.name = resp.data.name;
            this.originals.name = resp.data.name;
            return Permissions.findByProfileId(this.id);
          })
          .then(resp => {
            this.form.permissions = resp.data._embedded.permissions.map(x => x._links.self.href);
          })
          .catch(e => {
            console.error(e);
            this.sendError('ERROR_LOADING_PROFILE_DATA', e);
          })
          .finally(() => {
            this.isBusy = false;
          });
    }
  },
  data() {
    return {
      originals: {
        name: undefined
      },
      form: {
        name: null,
        permissions: [],
      }
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(32),
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (!value || value === '') return true

          if (this.originals.name && this.originals.name === value) return true;

          // simulate async call, fail for all logins with even length
          return Profiles.nameExists(value);
        }
      },
    }
  }
}
</script>

<style scoped>

</style>
