import http from "@/http";

const Permissions = {
    findAll: async function () {
        const resp = await http("permissions");
        return resp.data._embedded.permissions;
    },
    findByUserId: function(id) {
        return http.get('/users/' + id + '/permissions');
    },
    findByProfileId(id) {
        return http.get('/profiles/' + id + '/permissions');
    }
}

export default Permissions;
