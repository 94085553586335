import http from "@/http";

const Customers = {
    findAll(company) {
        console.log('/companies/' + company + '/customers');
        return http.get('/companies/' + company + '/customers')
    }
};

export default Customers;
