import http from "@/http";
import eventBus from "@/events";
import Constants from "@/constants";

const Sessions = {
    findAll: async function (ctx) {
        const page = ctx.currentPage - 1 || 0;
        const size = ctx.perPage || 20;
        const sort = ctx.sort || 'name';
        const sortDesc = ctx.sortDesc ? 'desc' : 'asc';
        const filter = ctx.filter ? ctx.filter : {from: new Date(), to: new Date()};
        const pagination = {page, size, sort, sortDesc};
        const params = {...filter, ...pagination};
        const resp = await http("/sessions/search/all", {params});
        eventBus.$emit(Constants.EVENT_ON_SESSION_LIST, resp.data.page.totalElements);
        return resp.data._embedded.accessTokens;
    },
    deleteById(id) {
        http.delete('sessions/' + id)
    },
    disable(id) {
        return http.patch('/sessions/' + id + '/disable');
    },
};

export default Sessions;
