import Numeral from 'numeral';
import Moment from 'moment';
import Constants from "@/constants";
import eventBus from "@/events";
import Token from "@/token";

export const Debug = {
    data() {
        return {
            debug: Constants.DEBUG
        }
    }
}

export const IsBusy = {
    data() {
        return {
            isBusy: false,
            saving: false,
            areBusy: []
        }
    }
};

export const Security = {
    data() {
        return {
            isAuthenticated: false,
        }
    },
    mounted() {
        eventBus.$on(Constants.EVENT_WHEN_USER_COMES_BACK, () => {
            this.isAuthenticated = true;
        });

        eventBus.$on(Constants.EVENT_WHEN_LOGOUT, () => {
            this.isAuthenticated = false;
        });
    }
}

export const SendsMessages = {
    methods: {
        sendMessage(message, params, type, error) {
            eventBus.$emit(Constants.EVENT_WHEN_MESSAGE_DISPLAY, message, params, type, error)
        },
        sendError(message, params, error) {
            this.sendMessage(message, params, 'danger', error);
        },
        sendInfo(message, params) {
            this.sendMessage(message, params, 'info');
        },
        sendWarning(message, params) {
            this.sendMessage(message, params, 'warning');
        }
    }
}

export const LoadData = {
    mixins: [IsBusy, Debug],
    mounted() {
        if (Token.isNotAuthenticated()) {
            eventBus.$on(Constants.EVENT_WHEN_USER_COMES_BACK, () => {
                this.loadData().then();
            });
        } else {
            const promise = this.loadData()
            if (promise) {
                promise.then();
            }
        }
    },
    methods: {
        async loadData() {
            this.isBusy = true;

            return new Promise((res) => {
                this.isBusy = false;
                res(true);
            });
        }
    }
}

export const NumberFilter = {
    filters: {
        number(number, format) {
            format = format || Constants.NUMBER_FORMAT;
            return Numeral(number).format(format);
        },
        currency(number, format) {
            format = format || Constants.CURRENCY_FORMAT;
            return Numeral(number).format(format);
        },
        percentage(number, format) {
            format = format || Constants.PERCENTAGE_FORMAT;
            return Numeral(number).format(format);
        }
    },
    methods: {
        getNumericValue(formattedValue) {
            return Numeral(formattedValue).value();
        }
    }
}

export const DateFilter = {
    filters: {
        date(date, format) {
            format = format || Constants.DATE_FORMAT;
            return Moment(date).format(format);
        }
    },
    methods: {
        getDate(date, format) {
            format = format || Constants.DATE_FORMAT;
            return Moment(date, format).date();
        }
    }
}

export const Table = {
    mixins: [IsBusy, Debug, SendsMessages],
    methods: {
        edit() {

        },
        details() {

        },
        remove() {
            alert('remove');
        },
        getRowIdx(id) {
            return this.items.map(x => x.id).indexOf(id);
        },
        getRow(id) {
            const idx = this.getRowIdx(id);
            return this.items[idx];
        },
        removeRow(id) {
            const idx = this.getRowIdx(id);
            this.items.splice(idx, 1);
        }
    },
    mounted() {
        this.page = +this.$route.query.page || 1;
        this.size = +this.$route.query.size || 20;
        this.sort = this.$route.query.sort || '';
        this.id = this.$route.params.id;
    },
    data() {
        return {
            id: undefined,
            items: [],
            page: 1,
            size: 20,
            sort: 'username',
            filter: {},
            form: {},
            endpoint: '/users',
            totalRows: 0
        }
    }
}

export const Form = {
    mixins: [LoadData, SendsMessages],
    mounted() {
        if (this.$route.params.id) {
            this.id = this.$route.params.id;
            this.getData();
        }
    },
    methods: {
        set(field, value) {
            this.form[field] = value
            this.$v.form[field].$touch()
        },
        reset(field) {
            this.$v.form[field].$reset();
        },
        getData() {

        }
    },
    computed: {
        state() {
            return function (field) {
                return this.$v.form[field].$dirty ? !this.$v.form[field].$invalid : null;
            }
        },
        errors() {
            return function (field) {
                const input = this.$v.form[field];
                const errors = Object.keys(input).filter(x => !x.startsWith('$'));
                const result = [];
                errors.forEach((error) => {
                    if (!input[error]) {
                        result.push({error: error.toUpperCase(), params: input.$params[error]});
                    }
                })
                return result;
            }
        }
    },
    data() {
        return {
            id: undefined
        }
    }
}

export const VModel = {
    props: ['value'],
    data() {
        return {
            content: this.value
        }
    },
    methods: {
        handleInput(val) {
            console.log(val);
            this.$emit('input', val);
        }
    }
}


export default {IsBusy, VModel, LoadData, Form, Table, NumberFilter, DateFilter};
