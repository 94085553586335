<template>
  <div>
    <div v-if="isBusy" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('PROFILE_LOADING_LABEL') }}</strong>
    </div>
    <b-card v-else :title="$t('PROFILES_TITLE')">
      <b-card-text>
        <b-form-checkbox-group
            id="profiles"
            v-model="value"
            name="profiles">
          <b-list-group>
            <b-list-group-item :key="profile.id"
                               v-for="profile in profiles.filter(x => search === '' || x.authority.indexOf(search) !== -1)">
              <b-form-checkbox
                  @change="handleInput"
                  style="display: block"
                  :value="profile._links.self.href">
                {{ profile.name }}
              </b-form-checkbox>
            </b-list-group-item>
          </b-list-group>
        </b-form-checkbox-group>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import http from "@/http";
import {LoadData, VModel} from "@/mixins";

export default {
  name: "ProfileList",
  mixins: [LoadData, VModel],
  methods: {
    loadData() {
      this.isBusy = true;
      return http
          .get('/profiles')
          .then(resp => this.profiles = resp.data._embedded.profiles)
          .finally(() => this.isBusy = false)
    },
    toggleProfile(profileId) {
      return function (e) {
        if (!e) {
          console.log('remove ' + profileId);
        } else {
          console.log('add ' + profileId);
        }
      }
    }
  },
  data() {
    return {
      search: '',
      profiles: [],
      content: this.value,
    }
  }
}
</script>

<style scoped>

</style>
