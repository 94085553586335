<template>
  <div>
    <div class="pb-3">
      <b-button class="ml-2" @click="refresh" variant="info">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('USERS_REFRESH_LABEL') }}</span>
      </b-button>
    </div>

    <!-- search -->
    <b-form>
      <b-container fluid>
        <b-row>
          <b-col>
            <b-form-group
                id="search-form-group"
                :description="$t('PRODUCT_SEARCH_INPUT_DESCRIPTION')"
                :label="$t('PRODUCT_SEARCH_LABEL')"
                label-for="search-input">
              <b-form-input id="search-input" v-model="search" @blur="refresh"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="4" lg="4" md="4" sm="12" xs="12" v-if="categories && categories.length > 0">
            <label>{{categories[0].name}}</label>
            <b-select
                v-model="classif1"
                :description="categories[0].name"
                :label="categories[0].name"
                @change="refresh"
            >
              <b-select-option :value="undefined">Todas</b-select-option>
              <b-select-option :value="c.id" v-for="c in categories[0].values" :key="c.id" >{{c.value}}</b-select-option>
            </b-select>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
    <!-- search -->

    <div v-if="isBusy" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('PRODUCT_LOADING_LABEL') }}</strong>
    </div>

    <div v-else>
      <b-pagination @change="loadProducts" :value="page" :per-page="perPage" :total-rows="total"
                    align="center"></b-pagination>
      <b-card-group class="w-100">
        <b-card
            class="mx-auto mb-2 mr-2"
            style="min-width: 250px; max-width: 250px"
            v-for="product in products"
            :key="product.id"
            :img-src="getProductImg(product.product_code)"
            img-height="250px"
            :title="product.product_name">
          <b-card-text>
            <div>
              <p>{{ product.long_description }}</p>
              <b-button :disabled="product.isWaiting" @click="toggleFavorite(product)"
                        v-if="product.isFavorite" class="mr-2" variant="info">
                <b-icon-circle-fill v-if="product.isWaiting" animation="throb"></b-icon-circle-fill>
                <b-icon-star-fill v-else></b-icon-star-fill>
              </b-button>
              <b-button :disabled="product.isWaiting" @click="toggleFavorite(product)"
                        v-else class="mr-2" variant="info">
                <b-icon-circle-fill v-if="product.isWaiting" animation="throb"></b-icon-circle-fill>
                <b-icon-star v-else></b-icon-star>
              </b-button>
              <b-button @click="showCartModal(product)" variant="info">
                <b-icon-basket></b-icon-basket>
              </b-button>
            </div>
          </b-card-text>
          <template #footer>
            <span style="display: block">{{ $t('PRICE_LABEL') }}: {{
                product.price1 | currency
              }} {{ product.currency_price_1 }}</span>
            <span style="display: block">{{ $t('EXISTENCE_LABEL') }}: {{ product.existence | number }} {{product.measure_unit}}</span>
            <div>
              <b-badge v-if="product.clasification_1 !== '(Ninguna)'" variant="info">{{ product.clasification_1 }}
              </b-badge>
              <b-badge v-if="product.clasification_2 !== '(Ninguna)'" variant="primary">{{ product.clasification_2 }}
              </b-badge>
              <b-badge v-if="product.clasification_3 !== '(Ninguna)'" variant="dark">{{ product.clasification_3 }}
              </b-badge>
              <b-badge v-if="product.clasification_4 !== '(Ninguna)'" variant="warning">{{ product.clasification_4 }}
              </b-badge>
              <b-badge v-if="product.clasification_5 !== '(Ninguna)'" variant="danger">{{ product.clasification_5 }}
              </b-badge>
              <b-badge v-if="product.clasification_6 !== '(Ninguna)'" variant="light">{{ product.clasification_6 }}
              </b-badge>
            </div>
          </template>
        </b-card>
      </b-card-group>
      <b-pagination @change="loadProducts" v-model="page" :per-page="perPage" :total-rows="total"
                    align="center"></b-pagination>
    </div>

    <b-modal header-bg-variant="primary" id="add-to-cart-modal" hide-footer
             :title="$t('ADD_PRODUCT_MODAL_TITLE', currentProduct)">

      <b-form @submit.prevent="addToCart">
        <b-form-group
            id="quantity-group"
            :label="$t('STOCK_AVAILABLE_LABEL', currentProduct)"
            :state="quantityState"
            label-for="quantity">
          <b-input :state="quantityState" v-model.trim="$v.form.quantity.$model" id="quantity"/>
          <b-form-invalid-feedback v-if="!$v.form.quantity.required">
            {{ $t('QUANTITY_ERROR_REQUIRED') }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.quantity.numeric">
            {{ $t('QUANTITY_ERROR_NUMERIC') }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.quantity.aboveZero">
            {{ $t('QUANTITY_ERROR_MIN') }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.quantity.maxValue">
            {{ $t('QUANTITY_ERROR_MAX') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-button :disabled="addingToCart" variant="primary" class="mt-2" type="submit">
          <b-icon-circle-fill animation="throb" class="ml-1" v-if="addingToCart"></b-icon-circle-fill>
          {{ $t('ADD_TO_CART_LABEL') }}
        </b-button>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
import http from "@/http";
import eventBus from "@/events";
import {IsBusy, NumberFilter, SendsMessages} from "@/mixins";
import Companies from "@/companies";
import Constants from "@/constants";
import Users from "@/users";
import {maxValue, numeric, required} from "vuelidate/lib/validators";
import {className} from "postcss-selector-parser";

const aboveZero = (value) => value > 0;

export default {
  name: "ProductList",
  mixins: [SendsMessages, IsBusy, NumberFilter],
  watch: {
    $route(to) {
      if (to.path.indexOf('favorites') !== -1) {
        // favorites
        this.loadProducts()
      }
      if (to.path.indexOf('products') !== -1) {
        // products
        this.loadProducts()
      }
    }
  },
  methods: {
    className,
    refresh() {
      this.loadCategories();
      this.loadProducts();
    },
    showCartModal(product) {
      this.currentProduct = product;
      this.$bvModal.show('add-to-cart-modal');
    },
    hideCartModal() {
      this.currentProduct = {};
      this.$bvModal.hide('add-to-cart-modal');
    },
    async loadProducts(page) {
      this.page = this.$route.query.page || page || 1;
      this.search = this.$route.query.search || this.search || '';
      this.isBusy = true;
      try {
        this.loading = true;
        const url1 = this.$route.path.indexOf('favorites') !== -1 ? '/companies/' + Companies.company + '/favorites' : '/companies/' + Companies.company + '/existences';
        const response1 = await http.get(url1, {
          params: {
            page: this.page,
            size: this.size,
            search: this.search,
            classif1: this.classif1,
            classif2: this.classif2,
            classif3: this.classif3,
            classif4: this.classif4,
            classif5: this.classif5,
            classif6: this.classif6,
          }
        });
        const url2 = this.$route.path.indexOf('favorites') !== -1 ? '/companies/' + Companies.company + '/favorites/count' : '/companies/' + Companies.company + '/existences/count';
        const response2 = await http.get(url2, {
          params: {
            page: this.page,
            size: this.size,
            search: this.search,
            classif1: this.classif1,
            classif2: this.classif2,
            classif3: this.classif3,
            classif4: this.classif4,
            classif5: this.classif5,
            classif6: this.classif6,
          }
        });
        this.products = response1.data.map(x => {
          return {
            ...x, ...{
              isWaiting: false,
              isFavorite: Users.currentUser && Users.currentUser.favoriteProducts.indexOf(x.product_code) !== -1
            }
          };
        });
        this.total = response2.data;
      } catch (e) {
        this.sendError('ERROR_LOADING_PRODUCTS', {}, e);
      } finally {
        this.isBusy = false;
      }
    },
    async loadCategories() {
      try {
        const response = await http.get('/companies/' + Companies.company + '/categories');
        this.categories = response.data;

        for (const category of this.categories) {
          const response = await http.get('/companies/' + Companies.company + '/categories/' + category.id + '/values');
          category.values = response.data;
        }
      } catch (e) {
        this.sendError('ERROR_LOADING_CATEGORIES', {}, e);
      }
    },
    getProductImg(code) {
      return Constants.baseUrl + '/companies/' + Companies.company + '/products/' + code + '/image';
    },
    async toggleFavorite(product) {
      const code = product.product_code;
      try {
        this.toggleWaiting(code);
        if (product.isFavorite) {
          await Users.removeFavoriteProduct(code);
        } else {
          await Users.addFavoriteProduct(code);
        }
        console.log(Users.currentUser);
      } catch (e) {
        this.sendError('ERROR_WHEN_TOGGLE_FAVORITE', {product: code}, e);
      } finally {
        this.toggleFavoriteUI(code);
        this.toggleWaiting(code);
      }
    },
    getCodeIdx(code) {
      return this.products.map(x => x.product_code).indexOf(code);
    },
    toggleFavoriteUI(code) {
      const isFav = this.products[this.getCodeIdx(code)].isFavorite;
      this.products[this.getCodeIdx(code)].isFavorite = !isFav;
    },
    toggleWaiting(code) {
      const isWaiting = this.products[this.getCodeIdx(code)].isWaiting;
      this.products[this.getCodeIdx(code)].isWaiting = !isWaiting;
    },
    async addToCart() {
      try {
        this.addingToCart = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        const data = {
          productCode: this.currentProduct.product_code,
          productName: this.currentProduct.product_name,
          measureUnit: this.currentProduct.measure_unit,
          quantity: this.form.quantity
        };
        await http.post('/users/cart', data);
        eventBus.$emit(Constants.EVENT_WHEN_ADDED_TO_CART, data);
        this.form.quantity = 0;
        this.$v.$reset();
        this.hideCartModal();
      } catch (e) {
        this.sendError('ERROR_WHEN_ADDED_TO_CART', {product: this.currentProduct.product_name}, e);
      } finally {
        this.addingToCart = false;
      }
    }
  },
  mounted() {
    if (Companies.company) {
      this.products = [];
      this.loadCategories();
      //this.loadProducts();
    }

    eventBus.$on(Constants.WHEN_COMPANY_SELECTED, () => {
      this.loadCategories();
      this.loadProducts();
    });

    eventBus.$on(Constants.WHEN_GLOBAL_SEARCH, function () {
      this.loadCategories();
      this.loadProducts();
    });

    eventBus.$on(Constants.WHEN_ORDER_CREATED, () => {
      this.refresh();
    })
  },
  validations() {
    return {
      form: {
        quantity: {
          numeric,
          required,
          aboveZero,
          maxValue: maxValue(this.currentProduct.existence)
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      products: [],
      page: 1,
      total: 1,
      perPage: 20,
      search: '',
      categories: [],
      classifications: [],
      currentProduct: {},
      addingToCart: false,
      form: {
        quantity: 0
      },
      waitAddToCart: false,
    }
  },
  computed: {
    categoriesWithValues() {
      return this.categories ? this.categories.filter(x => x.values && x.values.length > 0) : [];
    },
    quantityState() {
      return this.$v.form.quantity.$dirty ? !this.$v.form.quantity.$invalid : null;
    },
    stock() {
      return this.currentProduct.existence < 0 ? 0 : this.currentProduct.existence
    }
  }
}
</script>

<style scoped>

</style>
